import { createStore } from "vuex"
import auth from "@/store/auth"
import menu from "@/store/menu"
import dashboard from "@/store/dashboard"
import settings from "@/store/settings"
import company_onboarding from "@/store/company_onboarding"
import sanad_creation from "@/store/sanad_creation"
import userManagement from "@/store/user_management"
import callbackHistory from "@/store/calllback_history"


export default createStore({
    modules: {
        auth,
        menu,
        dashboard,
        settings,
        company_onboarding,
        sanad_creation,
        userManagement,
        callbackHistory
    }
})
