<template>
    <ProgressBar
        mode="indeterminate"
        style="height: 6px; background: linear-gradient(to left, #2a7050, #ffffff 100%)"
    />
</template>

<style scoped></style>

<script>
import MainService from "@/services/main.service.ts"
import { ToastSeverity } from "primevue/api"

export default {
    name: "ShortUrlRedirect",
    methods: {
        async getFullURL(urlHash) {
            MainService.getFullURL(urlHash)
                .then(response => {
                    window.location.href = response?.data?.result?.full_url
                })
                .catch(() => {
                    this.$toast.add({
                        severity: ToastSeverity.ERROR,
                        summary: this.$t("invalidLink"),
                        life: 5000
                    })
                    this.$router.push({ name: "home" })
                })
        }
    },
    async mounted() {
        await this.getFullURL(this.$route.params.urlHash)
    }
}
</script>
