import DashboardService from "@/services/dashboard.service.ts"
import store from "@/store/index"

export default {
    state: {
        dashboardInsights: {},
        dashboardCharts: {},
        dashboardDataType: null,
        dashboardDataYear: null
    },
    getters: {
        getDashboardInsights: state => state.dashboardInsights,
        getDashboardCharts: state => state.dashboardCharts,
        getDashboardDataYear: state => state.dashboardDataYear,
        getDashboardDataType: state => state.dashboardDataType
    },
    actions: {
        async fetchDashboardInsightData({ commit }, { params = {} }) {
            await DashboardService.getInsightData(store.getters.getUser?.type, params)
                .then(response => {
                    commit("setDashboardInsightData", {
                        insightData: response?.data?.result
                    })
                })
                .catch(error => {
                    console.error(error)
                })
        },
        async fetchDashboardChartData({ commit }, { chartType, params = {} }) {
            await DashboardService.getChartData(chartType, params)
                .then(response => {
                    commit("setDashboardChartData", {
                        chartType,
                        chartData: response?.data?.result
                    })
                })
                .catch(error => {
                    console.error(error)
                })
        },
        clearDashboardData({ commit }) {
            commit("clearDashboardData")
        },
        setDashboardDataYear({ commit }, year) {
            commit("setDashboardDataYear", year)
        },
        setDashboardDataType({ commit }, type) {
            commit("setDashboardDataType", type)
        }
    },
    mutations: {
        setDashboardInsightData(state, payload) {
            state.dashboardInsights = payload.insightData
        },
        setDashboardChartData(state, payload) {
            state.dashboardCharts[payload.chartType] = payload.chartData
        },
        setDashboardDataYear(state, year) {
            state.dashboardDataYear = year
        },
        setDashboardDataType(state, type) {
            state.dashboardDataType = type
        },
        clearDashboardData(state) {
            state.dashboardInsights = {}
            state.dashboardCharts = {}
            state.dashboardDataYear = null
            state.dashboardDataType = null
        }
    }
}
