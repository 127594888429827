import BaseService from "./BaseService.js";

const accountAppEndpoint = "/account";
const individualLoginCredentialsEndpoint = "/login/credentials/individual/";
const individualLoginEndpoint = "/login/individual/";
const individualRandomNumberEndpoint = "/login/individual/get-random-code/";
const individualCheckSSOTransactionStatusEndpoint = "/login/individual/random-code-status/";
const companyLoginEndpoint = "/login/company/";
const logoutEndpoint = "/logout/";
const iamCallbackEndpoint = "/verification/iam-callback/";
const verifyPhoneNumberEndpoint = "/verify-phone-number/";
const changePhoneNumberEndpoint = "/change-phone-number/";
const changePasswordEndpoint = "/change-password/";
const userDataEndpoint = "/user-data/";
const insufficientBalanceReminderSettingEndpoint =
    "/insufficient-balance-reminder-setting/";
export const refreshTokenEndpoint = "/token/refresh/";

export default class AccountService extends BaseService {
    static appEndpoint = accountAppEndpoint;

    static individualUserLogin() {
        const endpoint = this.endpoint(`${individualLoginEndpoint}`);
        return this.request("post", endpoint);
    }

    static individualUserCredentialsLogin(data) {
        const endpoint = this.endpoint(`${individualLoginCredentialsEndpoint}`);
        return this.request("post", endpoint, data);
    }

    static companyUserLogin(data) {
        const endpoint = this.endpoint(`${companyLoginEndpoint}`);
        return this.request("post", endpoint, data);
    }

    static logout() {
        const endpoint = this.endpoint(logoutEndpoint);
        return this.request("post", endpoint);
    }

    static processIAMCallback(state, code) {
        const endpoint = this.endpoint(iamCallbackEndpoint);
        const params = { state, code };
        return this.request("get", endpoint, { params });
    }

    static getUserData() {
        const endpoint = this.endpoint(userDataEndpoint);
        return this.request("get", endpoint);
    }

    static verifyPhoneNumber(data) {
        const endpoint = this.endpoint(verifyPhoneNumberEndpoint);
        return this.request("post", endpoint, data);
    }

    static changePhoneNumber(token, data) {
        const endpoint = this.endpoint(`${changePhoneNumberEndpoint}${token}/`);
        return this.request("post", endpoint, data);
    }

    static changePassword(token, data) {
        const endpoint = this.endpoint(`${changePasswordEndpoint}${token}/`);
        return this.request("patch", endpoint, data);
    }

    static insufficientBalanceReminderSetting(data) {
        const endpoint = this.endpoint(`${insufficientBalanceReminderSettingEndpoint}`);
        return this.request("patch", endpoint, data);
    }

    static refreshToken(refresh) {
        const data = { refresh };
        const endpoint = this.endpoint(`${refreshTokenEndpoint}`);
        return this.request("post", endpoint, data);
    }

    static individualGetRandomNumberVerification(data, configs: Object) {
        const endpoint = this.endpoint(`${individualRandomNumberEndpoint}`);
        return this.request("post", endpoint, data, configs);
    }

    static individualCheckSSOTransactionStatus(trans_id: string) {
        let endpoint = this.endpoint(`${individualCheckSSOTransactionStatusEndpoint}`);
        endpoint += trans_id + "/";
        return this.request("get", endpoint);
    }
}
