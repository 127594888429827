export default {
    state: {
        commercialNumberRes: {},
        companyGeneralDetailsRes: {},
        companyDelegatorDetailsRes: {},
        companyPaymentChooseRes: {},
        companyFileUploadRes: {}
    },
    getters: {
        getCommercialNumber: state => state.commercialNumberRes,
        getCompanyGeneralDetails: state => state.companyGeneralDetailsRes,
        getCompanyDelegatorDetails: state => state.companyDelegatorDetailsRes,
        getCompanyPaymentChoose: state => state.companyPaymentChooseRes,
        getCompanyFileUpload: state => state.companyFileUploadRes,
        getCompanyOnboardingRequestDetails: state => Object.assign({}, state.commercialNumberRes, state.companyGeneralDetailsRes, state.companyDelegatorDetailsRes, state.companyPaymentChooseRes, state.companyFileUploadRes)

    },
    actions: {
        handleCommercialNumberData ({ commit }, data) {
            console.log("CommercialNumberData", data)
            commit("setCommercialNumberData", {
                commercialNumberData: data
            })
        },
        handleCompanyGeneralDetailsData ({ commit }, data) {
            console.log("CompanyGeneralDetailsData", data)
            commit("setCompanyGeneralDetailsData", {
                companyGeneralDetailsData: data
            })
        },
        handleCompanyDelegatorDetailsData ({ commit }, data) {
            console.log("CompanyDelegatorDetailsData", data)
            commit("setCompanyDelegatorDetailsData", {
                companyDelegatorDetailsData: data
            })
        },
        handleCompanyPaymentChooseData ({ commit }, data) {
            console.log("CompanyPaymentChooseData", data)
            commit("setCompanyPaymentChooseData", {
                companyPaymentChooseData: data
            })
        },
        handleCompanyFileUploadData ({ commit }, data) {
            console.log("CompanyFileUploadData", data)
            commit("setCompanyFileUploadData", {
                companyFileUploadData: data
            })
        }
    },
    mutations: {
        setCommercialNumberData (state, payload) {
            state.commercialNumberRes = payload.commercialNumberData
        },
        setCompanyGeneralDetailsData (state, payload) {
            state.companyGeneralDetailsRes = payload.companyGeneralDetailsData
        },
        setCompanyDelegatorDetailsData (state, payload) {
            state.companyDelegatorDetailsRes = payload.companyDelegatorDetailsData
        },
        setCompanyPaymentChooseData (state, payload) {
            state.companyPaymentChooseRes = payload.companyPaymentChooseData
        },
        setCompanyFileUploadData (state, payload) {
            state.companyFileUploadRes = payload.companyFileUploadData
        },
        restCompanyOnboardingStore (state) {
            state.commercialNumberRes = {}
            state.companyGeneralDetailsRes = {}
            state.companyDelegatorDetailsRes = {}
            state.companyPaymentChooseRes = {}
            state.companyFileUploadRes = {}
        }
    }
}
