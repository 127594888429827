import AccountService from "@/services/account.service.ts"
import { decodeJwt } from "@/helpers/utility"

export default {
    state: {
        accessToken: localStorage.getItem("accessToken") || "",
        refreshToken: localStorage.getItem("refreshToken") || "",
        user: decodeJwt(localStorage.getItem("user")) || {}
    },
    mutations: {
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken
            localStorage.setItem("accessToken", accessToken)
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken
            localStorage.setItem("refreshToken", refreshToken)
        },
        setUser(state, user) {
            state.user = decodeJwt(user)
            localStorage.setItem("user", user)
        },
        clearAuthData(state) {
            state.accessToken = ""
            state.refreshToken = ""
            state.user = {}
            localStorage.removeItem("accessToken")
            localStorage.removeItem("refreshToken")
            localStorage.removeItem("user")
        }
    },
    actions: {
        async authenticate({ commit, dispatch }, data) {
            commit("setAccessToken", data?.access)
            commit("setRefreshToken", data?.refresh)
            await dispatch("updateUserData")
        },
        logout({ commit, dispatch }) {
            commit("clearAuthData")
            dispatch("clearMenuItems")
            dispatch("clearDashboardData")
        },
        setAccessToken({ commit }, accessToken) {
            commit("setAccessToken", accessToken)
        },
        setUser({ commit }, user) {
            commit("setUser", user)
        },
        async updateUserData({ commit }) {
            try {
                const {
                    data: { result: userData }
                } = await AccountService.getUserData()
                commit("setUser", userData || {})
                // clear dashboard data when user data updated "permissions"
                commit("clearDashboardData")
            } catch (error) {
                console.error("Error fetching user data:", error)
            }
        }
    },
    getters: {
        isAuthenticated(state) {
            return !!state.user.username
        },
        getUser(state) {
            return state.user
        },
        getAccessToken(state) {
            return state.accessToken
        },
        getRefreshToken(state) {
            return state.refreshToken
        },
        /**
         * Checks if the user has all specified permissions.
         */
        hasPermissions: state => permissions => {
            const userPermissions = state.user?.permissions || []
            return permissions.every(permission => userPermissions.includes(permission))
        },
        /**
         * Checks if the user has any of the specified permissions.
         */
        hasPermission: state => permissions => {
            const userPermissions = state.user?.permissions || []
            return userPermissions.some(permission => permissions.includes(permission))
        }
    }
}
