import BaseService from "./BaseService.js";

const mainAppEndpoint = "/main";
const contactUsEndpoint = "/contact-us";
const sidebarMenuEndpoint = "/menu-items";
const settingsEndpoint = "/settings";
const verifyEmailAppEndpoint = "/verify-email";
const autocompleteAppEndpoint = "/autocomplete";
const shortenedFullUrlEndpoint = "/shortened-full-url";
export default class MainService extends BaseService {
    static appEndpoint = mainAppEndpoint;

    static sendContactUsRequest(data) {
        const endpoint = this.endpoint(`${contactUsEndpoint}/`);
        return this.request("post", endpoint, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    static getMenuItems() {
        const endpoint = this.endpoint(`${sidebarMenuEndpoint}/`);
        return this.request("get", endpoint);
    }

    static getSettings() {
        const endpoint = this.endpoint(settingsEndpoint);
        return this.request("get", `${endpoint}/`);
    }

    static verifyEmail({ uidb64, token }) {
        return this.request(
            "get",
            this.endpoint(`${verifyEmailAppEndpoint}/${uidb64}/${token}`),
        );
    }

    static getFullURL(urlHash: string) {
        return this.request("get", this.endpoint(`${shortenedFullUrlEndpoint}/${urlHash}`));
    }

    static autocomplete({ identifier }, params) {
        return this.request(
            "get",
            this.endpoint(`${autocompleteAppEndpoint}/${identifier}/`),
            params,
        );
    }
}
