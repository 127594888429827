<template>
    <div id="sidebar" class="p-sidebar">
        <div id="nafith-logo" class="m-2 mt-3">
            <img
                alt="nafith-logo"
                height="40"
                src="../../../assets/images/nafith_logo.png"
            />
        </div>
        <div class="sidebar-sections">
            <div class="sidebar-top">
                <ul class="list-unstyled">
                    <li
                        v-for="(menuItem, index) in menuItems"
                        :key="index"
                        :class="{
                            active: menuItem.url && $route?.name?.includes(menuItem.url),
                        }"
                    >
                        <router-link
                            :to="{ name: menuItem?.url }"
                            @click.prevent="sidebarVisible = false"
                        >
                            <span class="link-icon"
                                ><i :class="`hgi-stroke hgi-${menuItem.icon}`"></i
                            ></span>
                            <span class="link-text">{{ menuItem.label }}</span>
                            <span
                                v-if="menuItem?.badge"
                                class="badge text-warning notification"
                                >{{ menuItem.badge }}</span
                            >
                        </router-link>
                    </li>
                </ul>
                <router-link
                    class="button primary fill lg create"
                    :to="{ name: 'portalSanadCreat' }"
                    v-if="showSanadCreationButton"
                >
                    <i class="hgi-stroke hgi-plus-sign"></i>
                    <span>{{ $t("createSanad") }}</span>
                </router-link>
                <router-link
                    v-if="isLawyerCompany"
                    class="button primary secondary-solid lg manage"
                    :to="{ name: 'portalContract' }"
                >
                    <span>{{ $t("contractManagement") }}</span>
                    <i class="hgi-stroke hgi-share-03"></i>
                </router-link>
            </div>
            <div class="sidebar-bottom">
                <div class="options">
                    <router-link :to="'/home'" class="item">
                        <span>{{ $t("home") }}</span>
                    </router-link>
                    <router-link :to="'/execution-sanads'" class="item">
                        <span>{{ $t("executionSanads") }}</span>
                    </router-link>
                    <router-link :to="'/faq'" class="item">
                        <span>{{ $t("faq") }}</span>
                    </router-link>
                    <router-link :to="'/contact-us'" class="item">
                        <span>{{ $t("contactUs") }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserAuthMixin from "@/mixins/UserAuthMixin"
import { userTypes } from "@/enums"

export default {
    name: "SidebarSection",
    mixins: [UserAuthMixin],
    inject: ["emitter"],
    data() {
        return {
            sidebarVisible: false,
            menuItems: []
        }
    },
    async created() {
        await this.fetchMenuItems()
    },
    methods: {
        async fetchMenuItems(forceFetch = false) {
            await this.$store.dispatch("fetchMenuItems", forceFetch)
            this.menuItems = this.$store.getters.getMenuItems
        }
    },
    computed: {
        showSanadCreationButton() {
            const { type, company, permissions } = this.user
            return (
                type === userTypes.INDIVIDUAL ||
                (type === userTypes.COMPANY &&
                    (company?.is_owner || permissions?.includes("sanad.create_sanad")))
            )
        }
    },
    mounted() {
        this.emitter.on("fetchMenuItems", async () => {
            await this.fetchMenuItems(true)
        })
    }
}
</script>
