<template>
    <footer>
        <div class="container">
            <div class="row pt-4 text-center">
                <div class="col">
                    <div class="partner-card pt-2">
                        <img
                            src="https://statics.nafith.sa/static/images/moj-700x400.png"
                            id="moj-logo"
                            height="80"
                        />
                    </div>
                </div>
            </div>
            <div>
                <div class="about-sanad-subtitle p-4">{{ $t("electronicPlatform") }}</div>
            </div>
            <div class="support-section">
                <div class="text-center">
                    <img
                        height="30"
                        src="https://statics.nafith.sa/static/images/phone.png"
                    />
                    <span class="call-us-support">
                        {{ $t("unifiedNumber") }}
                        <a
                            class="blue-text support-number"
                            :href="`tel:${callCenterNumber}`"
                        >
                            {{ callCenterNumber }}
                        </a>
                    </span>
                </div>
            </div>
            <hr class="border-dashed" />
            <div class="copy-right py-4">
                {{ $t("allRightsReserved") }}
                © {{ year }}
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            year: new Date().getFullYear()
        }
    },
    computed: {
        callCenterNumber() {
            return (
                this.$store.getters.getSettings?.nafith_call_center_phone_number ??
                "920035127"
            )
        }
    }
}
</script>

<style scoped>
footer {
    background-color: #fffdf7;
}

footer .footer-top {
    padding: 40px 0;
}

footer .footer-bottom {
    padding: 20px 0;
    /*border-top: 1px solid rgba(255,255,255,.2);*/
}

footer .footer-brand img {
    height: 45px;
    margin-bottom: 10px;
}

footer .footer-link {
    padding-bottom: 10px;
    float: right;
    color: #fff;
    list-style-type: none;
    margin-left: 10px;
}

.footer-bottom-grey ul.footer-links {
    width: 102%;
    padding: 25px 0;
    font-size: 14px;
}

footer .footer-link a {
    color: #fff;
    border-left: 1px solid #fff;
    padding-left: 10px;
    height: 20px;
    float: right;
}

footer .footer-link:last-child a {
    border-left: 0;
}

footer .footer-link a:hover {
    color: #bcbebd;
    text-decoration: none;
}

footer .footer-copyright {
    font-size: 14px;
    text-align: center;
}

footer .footer-top-off-white .partner-card,
footer .footer-link .partner-card {
    text-align: center;
    margin-bottom: 20px;
}

footer .footer-bottom-grey {
    background-color: #4f5150;
    color: #ffffff;
}

footer .about-sanad-subtitle {
    color: #848484;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
}

.footer-support-phone {
    border: 1px solid #50515085;
    padding: 6px 20px;
    border-radius: 5px;
    color: #505150 !important;
    display: block;
    text-align: center;
    text-decoration: none !important;
    font-size: 14px;
}

.footer-support-phone i {
    font-size: 15px;
    margin-right: 10px;
}

.footer-links {
    float: right;
    width: 100%;
    margin-bottom: 0;
}
</style>
