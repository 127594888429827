import { computed } from "vue"
import { useI18n } from "vue-i18n"

export const useHandleEnums = () => {
    const { t: $t } = useI18n({})

    const arabicDaysNames = computed(() => [
        $t("saturday"),
        $t("Sunday"),
        $t("Monday"),
        $t("Tuesday"),
        $t("Wednesday"),
        $t("Thursday"),
        $t("Friday")
    ])

    const gregorianMonthsNames = computed(() => [
        $t("January"),
        $t("February"),
        $t("March"),
        $t("April"),
        $t("May"),
        $t("June"),
        $t("July"),
        $t("August"),
        $t("September"),
        $t("October"),
        $t("November"),
        $t("December")
    ])

    const hijriMonthNames = computed(() => [
        $t("Muharram"),
        $t("Safar"),
        $t("Rabi' al-Awwal"),
        $t("Rabi' al-Thani"),
        $t("Jumada al-Awwal"),
        $t("Jumada al-Thani"),
        $t("Rajab"),
        $t("Sha'ban"),
        $t("Ramadan"),
        $t("Shawwal"),
        $t("Dhu al-Qi'dah"),
        $t("Dhu al-Hijjah")
    ])

    const saudiArabiaCities = computed(() => [
        {
            label: $t("mainCities"),
            options: [{
                label: $t("riyadh"),
                value: 1
            }, {
                label: $t("mecca"),
                value: 19
            }, {
                label: $t("jeddah"),
                value: 20
            }, {
                label: $t("alTayif"),
                value: 21
            }, {
                label: $t("medina"),
                value: 30
            }, {
                label: $t("buraydah"),
                value: 37
            }, {
                label: $t("dammam"),
                value: 47
            }, {
                label: $t("alAhsa"),
                value: 48
            }, {
                label: $t("hfrAlBatn"),
                value: 49
            }, {
                label: $t("abha"),
                value: 57
            }, {
                label: $t("khamisMushait"),
                value: 58
            }, {
                label: $t("tabuk"),
                value: 68
            }, {
                label: $t("hail"),
                value: 74
            }, {
                label: $t("earear"),
                value: 78
            }, {
                label: $t("jizan"),
                value: 81
            }, {
                label: $t("najran"),
                value: 94
            }, {
                label: $t("alBahah"),
                value: 102
            }, {
                label: $t("sakaka"),
                value: 109
            }]
        },
        {
            label: $t("other"),
            options: [{
                label: $t("alDaraeih"),
                value: 2
            }, {
                label: $t("alKharaj"),
                value: 3
            }, {
                label: $t("alDawadimiu"),
                value: 4
            }, {
                label: $t("alMujamaeuh"),
                value: 5
            }, {
                label: $t("alQuayeiuh"),
                value: 6
            }, {
                label: $t("wadiAlDawasir"),
                value: 7
            }, {
                label: $t("alAflaj"),
                value: 8
            }, {
                label: $t("alZulufiu"),
                value: 9
            }, {
                label: $t("hawtatanBaniTamim"),
                value: 10
            }, {
                label: $t("eafif"),
                value: 11
            }, {
                label: $t("alSalil"),
                value: 12
            }, {
                label: $t("darma"),
                value: 13
            }, {
                label: $t("alMuzahimih"),
                value: 14
            }, {
                label: $t("ramah"),
                value: 15
            }, {
                label: $t("thadiq"),
                value: 16
            }, {
                label: $t("harimla"),
                value: 17
            }, {
                label: $t("alhariq"),
                value: 18
            }, {
                label: $t("alQunafdhuh"),
                value: 22
            }, {
                label: $t("alLiyth"),
                value: 23
            }, {
                label: $t("rabigh"),
                value: 24
            }, {
                label: $t("alJumum"),
                value: 25
            }, {
                label: $t("khalis"),
                value: 26
            }, {
                label: $t("alKamil"),
                value: 27
            }, {
                label: $t("runih"),
                value: 28
            }, {
                label: $t("tarabah"),
                value: 29
            }, {
                label: $t("yanbae"),
                value: 31
            }, {
                label: $t("alOla"),
                value: 32
            }, {
                label: $t("alMahd"),
                value: 33
            }, {
                label: $t("badr"),
                value: 34
            }, {
                label: $t("khaybar"),
                value: 35
            }, {
                label: $t("alHinakia"),
                value: 36
            }, {
                label: $t("eanizah"),
                value: 38
            }, {
                label: $t("alrus"),
                value: 39
            }, {
                label: $t("alMudhanib"),
                value: 40
            }, {
                label: $t("alBikiriuh"),
                value: 41
            }, {
                label: $t("alBadayie"),
                value: 42
            }, {
                label: $t("alAsyah"),
                value: 43
            }, {
                label: $t("alNubhaniuh"),
                value: 44
            }, {
                label: $t("euyunAlJuaa"),
                value: 45
            }, {
                label: $t("alShamasiuh"),
                value: 46
            }, {
                label: $t("alJabil"),
                value: 50
            }, {
                label: $t("alQatif"),
                value: 51
            }, {
                label: $t("alKhubar"),
                value: 52
            }, {
                label: $t("alKhafji"),
                value: 53
            }, {
                label: $t("rasTanuruh"),
                value: 54
            }, {
                label: $t("abqyq"),
                value: 55
            }, {
                label: $t("qaryatAlAlia"),
                value: 56
            }, {
                label: $t("bayshah"),
                value: 59
            }, {
                label: $t("alNamas"),
                value: 60
            }, {
                label: $t("mahayilAsir"),
                value: 61
            }, {
                label: $t("saratObaiedah"),
                value: 62
            }, {
                label: $t("tuthliyt"),
                value: 63
            }, {
                label: $t("rijalAlmaa"),
                value: 64
            }, {
                label: $t("ahdRafidih"),
                value: 65
            }, {
                label: $t("balqurn"),
                value: 66
            }, {
                label: $t("alMajariduh"),
                value: 67
            }, {
                label: $t("alWajh"),
                value: 69
            }, {
                label: $t("daba"),
                value: 70
            }, {
                label: $t("tima"),
                value: 71
            }, {
                label: $t("amlij"),
                value: 72
            }, {
                label: $t("haql"),
                value: 73
            }, {
                label: $t("biqaea"),
                value: 75
            }, {
                label: $t("alGhizaluh"),
                value: 76
            }, {
                label: $t("alShannan"),
                value: 77
            }, {
                label: $t("rafhaa"),
                value: 79
            }, {
                label: $t("tarif"),
                value: 80
            }, {
                label: $t("sibyaa"),
                value: 82
            }, {
                label: $t("abuArish"),
                value: 83
            }, {
                label: $t("samith"),
                value: 84
            }, {
                label: $t("alHarth"),
                value: 85
            }, {
                label: $t("damad"),
                value: 86
            }, {
                label: $t("alRiyth"),
                value: 87
            }, {
                label: $t("baysh"),
                value: 88
            }, {
                label: $t("farsan"),
                value: 89
            }, {
                label: $t("ahdAlMasarihih"),
                value: 90
            }, {
                label: $t("alEidabiu"),
                value: 91
            }, {
                label: $t("alEariduh"),
                value: 92
            }, {
                label: $t("alDarb"),
                value: 93
            }, {
                label: $t("shururuh"),
                value: 95
            }, {
                label: $t("hubuwnana"),
                value: 96
            }, {
                label: $t("badrAlJanub"),
                value: 97
            }, {
                label: $t("yadamuh"),
                value: 98
            }, {
                label: $t("thar"),
                value: 99
            }, {
                label: $t("khabash"),
                value: 100
            }, {
                label: $t("alKharkhir"),
                value: 101
            }, {
                label: $t("biljurshi"),
                value: 103
            }, {
                label: $t("alMunadiq"),
                value: 104
            }, {
                label: $t("alMakhawa"),
                value: 105
            }, {
                label: $t("alEaqiq"),
                value: 106
            }, {
                label: $t("quluh"),
                value: 107
            }, {
                label: $t("alQuraa"),
                value: 108
            }, {
                label: $t("alQuriyat"),
                value: 110
            }, {
                label: $t("dawmatAlJundal"),
                value: 111
            }, {
                label: $t("alZahran"),
                value: 112
            }, {
                label: $t("shuqara"),
                value: 113
            }, {
                label: $t("muhafazatAlGhat"),
                value: 114
            }, {
                label: $t("tabirajl"),
                value: 115
            }, {
                label: $t("alMawiyuh"),
                value: 116
            }, {
                label: $t("alBarak"),
                value: 117
            }, {
                label: $t("uqlatAlSuqur"),
                value: 118
            }, {
                label: $t("alEays"),
                value: 119
            }, {
                label: $t("alHayit"),
                value: 120
            }, {
                label: $t("aduma"),
                value: 121
            }, {
                label: $t("tanawma"),
                value: 122
            }, {
                label: $t("zahranAlJanub"),
                value: 123
            }, {
                label: $t("mawqiq"),
                value: 124
            }, {
                label: $t("alKharma"),
                value: 125
            }, {
                label: $t("aleardiatAlshamalia"),
                value: 128
            }, {
                label: $t("marrat"),
                value: 129
            }, {
                label: $t("alHajra"),
                value: 130
            }, {
                label: $t("aleardiatAljanubia"),
                value: 131
            }, {
                label: $t("wadiAlFare"),
                value: 132
            }, {
                label: $t("alShamli"),
                value: 133
            }, {
                label: $t("alTwal"),
                value: 134
            }, {
                label: $t("duriya"),
                value: 135
            }, {
                label: $t("fayfaa"),
                value: 137
            }, {
                label: $t("alkhubaraWaRiaduha"),
                value: 138
            }, {
                label: $t("alriyn"),
                value: 139
            }, {
                label: $t("harub"),
                value: 140
            }, {
                label: $t("maysan"),
                value: 141
            }, {
                label: $t("bariq"),
                value: 142
            }, {
                label: $t("alHarija"),
                value: 143
            }, {
                label: $t("ghamidAlzinad"),
                value: 144
            }, {
                label: $t("alddayirBaniMalik"),
                value: 145
            }]
        }
    ])
    const isArabic = computed(() => { if (localStorage.getItem("language")) { return localStorage.getItem("language") === "ar" } return true })
    return {
        arabicDaysNames,
        gregorianMonthsNames,
        hijriMonthNames,
        saudiArabiaCities,
        isArabic
    }
}

export const HandleEnums = {
    computed: {
        arabicDaysNames () {
            return [
                this.$t("saturday"),
                this.$t("Sunday"),
                this.$t("Monday"),
                this.$t("Tuesday"),
                this.$t("Wednesday"),
                this.$t("Thursday"),
                this.$t("Friday")

            ]
        },
        gregorianMonthsNames () {
            return [
                this.$t("January"),
                this.$t("February"),
                this.$t("March"),
                this.$t("April"),
                this.$t("May"),
                this.$t("June"),
                this.$t("July"),
                this.$t("August"),
                this.$t("September"),
                this.$t("October"),
                this.$t("November"),
                this.$t("December")
            ]
        },
        hijriMonthNames () {
            return [
                this.$t("Muharram"),
                this.$t("Safar"),
                this.$t("Rabi' al-Awwal"),
                this.$t("Rabi' al-Thani"),
                this.$t("Jumada al-Awwal"),
                this.$t("Jumada al-Thani"),
                this.$t("Rajab"),
                this.$t("Sha'ban"),
                this.$t("Ramadan"),
                this.$t("Shawwal"),
                this.$t("Dhu al-Qi'dah"),
                this.$t("Dhu al-Hijjah")
            ]
        },
        saudiArabiaCities () {
            return [
                {
                    label: this.$t("mainCities"),
                    options: [{
                        label: this.$t("riyadh"),
                        value: 1
                    }, {
                        label: this.$t("mecca"),
                        value: 19
                    }, {
                        label: this.$t("jeddah"),
                        value: 20
                    }, {
                        label: this.$t("alTayif"),
                        value: 21
                    }, {
                        label: this.$t("medina"),
                        value: 30
                    }, {
                        label: this.$t("buraydah"),
                        value: 37
                    }, {
                        label: this.$t("dammam"),
                        value: 47
                    }, {
                        label: this.$t("alAhsa"),
                        value: 48
                    }, {
                        label: this.$t("hfrAlBatn"),
                        value: 49
                    }, {
                        label: this.$t("abha"),
                        value: 57
                    }, {
                        label: this.$t("khamisMushait"),
                        value: 58
                    }, {
                        label: this.$t("tabuk"),
                        value: 68
                    }, {
                        label: this.$t("hail"),
                        value: 74
                    }, {
                        label: this.$t("earear"),
                        value: 78
                    }, {
                        label: this.$t("jizan"),
                        value: 81
                    }, {
                        label: this.$t("najran"),
                        value: 94
                    }, {
                        label: this.$t("alBahah"),
                        value: 102
                    }, {
                        label: this.$t("sakaka"),
                        value: 109
                    }]
                },
                {
                    label: this.$t("other"),
                    options: [{
                        label: this.$t("alDaraeih"),
                        value: 2
                    }, {
                        label: this.$t("alKharaj"),
                        value: 3
                    }, {
                        label: this.$t("alDawadimiu"),
                        value: 4
                    }, {
                        label: this.$t("alMujamaeuh"),
                        value: 5
                    }, {
                        label: this.$t("alQuayeiuh"),
                        value: 6
                    }, {
                        label: this.$t("wadiAlDawasir"),
                        value: 7
                    }, {
                        label: this.$t("alAflaj"),
                        value: 8
                    }, {
                        label: this.$t("alZulufiu"),
                        value: 9
                    }, {
                        label: this.$t("hawtatanBaniTamim"),
                        value: 10
                    }, {
                        label: this.$t("eafif"),
                        value: 11
                    }, {
                        label: this.$t("alSalil"),
                        value: 12
                    }, {
                        label: this.$t("darma"),
                        value: 13
                    }, {
                        label: this.$t("alMuzahimih"),
                        value: 14
                    }, {
                        label: this.$t("ramah"),
                        value: 15
                    }, {
                        label: this.$t("thadiq"),
                        value: 16
                    }, {
                        label: this.$t("harimla"),
                        value: 17
                    }, {
                        label: this.$t("alhariq"),
                        value: 18
                    }, {
                        label: this.$t("alQunafdhuh"),
                        value: 22
                    }, {
                        label: this.$t("alLiyth"),
                        value: 23
                    }, {
                        label: this.$t("rabigh"),
                        value: 24
                    }, {
                        label: this.$t("alJumum"),
                        value: 25
                    }, {
                        label: this.$t("khalis"),
                        value: 26
                    }, {
                        label: this.$t("alKamil"),
                        value: 27
                    }, {
                        label: this.$t("runih"),
                        value: 28
                    }, {
                        label: this.$t("tarabah"),
                        value: 29
                    }, {
                        label: this.$t("yanbae"),
                        value: 31
                    }, {
                        label: this.$t("alOla"),
                        value: 32
                    }, {
                        label: this.$t("alMahd"),
                        value: 33
                    }, {
                        label: this.$t("badr"),
                        value: 34
                    }, {
                        label: this.$t("khaybar"),
                        value: 35
                    }, {
                        label: this.$t("alHinakia"),
                        value: 36
                    }, {
                        label: this.$t("eanizah"),
                        value: 38
                    }, {
                        label: this.$t("alrus"),
                        value: 39
                    }, {
                        label: this.$t("alMudhanib"),
                        value: 40
                    }, {
                        label: this.$t("alBikiriuh"),
                        value: 41
                    }, {
                        label: this.$t("alBadayie"),
                        value: 42
                    }, {
                        label: this.$t("alAsyah"),
                        value: 43
                    }, {
                        label: this.$t("alNubhaniuh"),
                        value: 44
                    }, {
                        label: this.$t("euyunAlJuaa"),
                        value: 45
                    }, {
                        label: this.$t("alShamasiuh"),
                        value: 46
                    }, {
                        label: this.$t("alJabil"),
                        value: 50
                    }, {
                        label: this.$t("alQatif"),
                        value: 51
                    }, {
                        label: this.$t("alKhubar"),
                        value: 52
                    }, {
                        label: this.$t("alKhafji"),
                        value: 53
                    }, {
                        label: this.$t("rasTanuruh"),
                        value: 54
                    }, {
                        label: this.$t("abqyq"),
                        value: 55
                    }, {
                        label: this.$t("qaryatAlAlia"),
                        value: 56
                    }, {
                        label: this.$t("bayshah"),
                        value: 59
                    }, {
                        label: this.$t("alNamas"),
                        value: 60
                    }, {
                        label: this.$t("mahayilAsir"),
                        value: 61
                    }, {
                        label: this.$t("saratObaiedah"),
                        value: 62
                    }, {
                        label: this.$t("tuthliyt"),
                        value: 63
                    }, {
                        label: this.$t("rijalAlmaa"),
                        value: 64
                    }, {
                        label: this.$t("ahdRafidih"),
                        value: 65
                    }, {
                        label: this.$t("balqurn"),
                        value: 66
                    }, {
                        label: this.$t("alMajariduh"),
                        value: 67
                    }, {
                        label: this.$t("alWajh"),
                        value: 69
                    }, {
                        label: this.$t("daba"),
                        value: 70
                    }, {
                        label: this.$t("tima"),
                        value: 71
                    }, {
                        label: this.$t("amlij"),
                        value: 72
                    }, {
                        label: this.$t("haql"),
                        value: 73
                    }, {
                        label: this.$t("biqaea"),
                        value: 75
                    }, {
                        label: this.$t("alGhizaluh"),
                        value: 76
                    }, {
                        label: this.$t("alShannan"),
                        value: 77
                    }, {
                        label: this.$t("rafhaa"),
                        value: 79
                    }, {
                        label: this.$t("tarif"),
                        value: 80
                    }, {
                        label: this.$t("sibyaa"),
                        value: 82
                    }, {
                        label: this.$t("abuArish"),
                        value: 83
                    }, {
                        label: this.$t("samith"),
                        value: 84
                    }, {
                        label: this.$t("alHarth"),
                        value: 85
                    }, {
                        label: this.$t("damad"),
                        value: 86
                    }, {
                        label: this.$t("alRiyth"),
                        value: 87
                    }, {
                        label: this.$t("baysh"),
                        value: 88
                    }, {
                        label: this.$t("farsan"),
                        value: 89
                    }, {
                        label: this.$t("ahdAlMasarihih"),
                        value: 90
                    }, {
                        label: this.$t("alEidabiu"),
                        value: 91
                    }, {
                        label: this.$t("alEariduh"),
                        value: 92
                    }, {
                        label: this.$t("alDarb"),
                        value: 93
                    }, {
                        label: this.$t("shururuh"),
                        value: 95
                    }, {
                        label: this.$t("hubuwnana"),
                        value: 96
                    }, {
                        label: this.$t("badrAlJanub"),
                        value: 97
                    }, {
                        label: this.$t("yadamuh"),
                        value: 98
                    }, {
                        label: this.$t("thar"),
                        value: 99
                    }, {
                        label: this.$t("khabash"),
                        value: 100
                    }, {
                        label: this.$t("alKharkhir"),
                        value: 101
                    }, {
                        label: this.$t("biljurshi"),
                        value: 103
                    }, {
                        label: this.$t("alMunadiq"),
                        value: 104
                    }, {
                        label: this.$t("alMakhawa"),
                        value: 105
                    }, {
                        label: this.$t("alEaqiq"),
                        value: 106
                    }, {
                        label: this.$t("quluh"),
                        value: 107
                    }, {
                        label: this.$t("alQuraa"),
                        value: 108
                    }, {
                        label: this.$t("alQuriyat"),
                        value: 110
                    }, {
                        label: this.$t("dawmatAlJundal"),
                        value: 111
                    }, {
                        label: this.$t("alZahran"),
                        value: 112
                    }, {
                        label: this.$t("shuqara"),
                        value: 113
                    }, {
                        label: this.$t("muhafazatAlGhat"),
                        value: 114
                    }, {
                        label: this.$t("tabirajl"),
                        value: 115
                    }, {
                        label: this.$t("alMawiyuh"),
                        value: 116
                    }, {
                        label: this.$t("alBarak"),
                        value: 117
                    }, {
                        label: this.$t("uqlatAlSuqur"),
                        value: 118
                    }, {
                        label: this.$t("alEays"),
                        value: 119
                    }, {
                        label: this.$t("alHayit"),
                        value: 120
                    }, {
                        label: this.$t("aduma"),
                        value: 121
                    }, {
                        label: this.$t("tanawma"),
                        value: 122
                    }, {
                        label: this.$t("zahranAlJanub"),
                        value: 123
                    }, {
                        label: this.$t("mawqiq"),
                        value: 124
                    }, {
                        label: this.$t("alKharma"),
                        value: 125
                    }, {
                        label: this.$t("aleardiatAlshamalia"),
                        value: 128
                    }, {
                        label: this.$t("marrat"),
                        value: 129
                    }, {
                        label: this.$t("alHajra"),
                        value: 130
                    }, {
                        label: this.$t("aleardiatAljanubia"),
                        value: 131
                    }, {
                        label: this.$t("wadiAlFare"),
                        value: 132
                    }, {
                        label: this.$t("alShamli"),
                        value: 133
                    }, {
                        label: this.$t("alTwal"),
                        value: 134
                    }, {
                        label: this.$t("duriya"),
                        value: 135
                    }, {
                        label: this.$t("fayfaa"),
                        value: 137
                    }, {
                        label: this.$t("alkhubaraWaRiaduha"),
                        value: 138
                    }, {
                        label: this.$t("alriyn"),
                        value: 139
                    }, {
                        label: this.$t("harub"),
                        value: 140
                    }, {
                        label: this.$t("maysan"),
                        value: 141
                    }, {
                        label: this.$t("bariq"),
                        value: 142
                    }, {
                        label: this.$t("alHarija"),
                        value: 143
                    }, {
                        label: this.$t("ghamidAlzinad"),
                        value: 144
                    }, {
                        label: this.$t("alddayirBaniMalik"),
                        value: 145
                    }]
                }
            ]
        },
        isArabic () {
            if (localStorage.getItem("language")) { return localStorage.getItem("language") === "ar" } return true
        }
    }
}
