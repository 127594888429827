<template>
    <header
        v-if="
            (!user.isAuthenticated && !$route?.name?.includes('companyOnboarding')) || (!isPortalRoute && !$route?.name?.includes('companyOnboarding') && !isMobile)
        "
        class="desktop-header logged-out-header"
        :class="[{ unscrolled: this.$route.name === 'home' && !isScrolledDown }, 'home']"
    >
        <nav class="navbar navbar-expand-md">
            <button
                class="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-label="Toggle navigation"
                aria-expanded="false"
                type="button"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="/">
                <img alt="nafith-logo" src="@/assets/images/nafith_logo.png" />
            </a>
            <div
                id="navbarNavAltMarkup"
                class="collapse navbar-collapse pr-md-5 text-right "
            >
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link :to="'/home'" class="nav-link">
                            <span class="text-decoration-none">{{ $t("home") }}</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="'/execution-sanads'" class="nav-link">
                            <span>{{ $t("executionSanads") }}</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="'/faq'" class="nav-link">
                            <span>{{ $t("faq") }}</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="'/contact-us'" class="nav-link">
                            <span>{{ $t("contactUs") }}</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link
                            v-if="user.isAuthenticated"
                            :to="{ name: 'portalIndexView' }"
                            class="nav-link"
                        >
                            <span>{{ $t("services") }}</span>
                        </router-link>
                    </li>

                    <div v-if="user.isAuthenticated" class="d-md-none">
                        <Divider></Divider>
                        <ul class="list-unstyled">
                            <li
                                v-for="(menuItem, index) in menuItems"
                                :key="index"
                                :class="{
                                    active:
                                        menuItem.url &&
                                        $route?.name?.includes(menuItem.url),
                                }"
                            >
                                <router-link :to="{ name: menuItem?.url }">
                                    <span class="link-icon"
                                        ><i :class="`hgi-stroke hgi-${menuItem.icon}`"></i
                                    ></span>
                                    <span class="link-text">{{ menuItem.label }}</span>
                                    <span
                                        v-if="menuItem?.badge"
                                        class="badge text-warning notification"
                                        >{{ menuItem.badge }}</span
                                    >
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </ul>
            </div>
            <div class="hide-in-mobile nafith-display-none-500 nafith-space-gap-20" v-if="!user.isAuthenticated">
                <div class="">
                    <router-link
                        :to="{ name: 'individualLogin' }"
                        :class="[{'active-button-nav': currentRoute === 'individualLogin'},'text-text-default-color','line-height-24','font-16','nafith-space-gap-0']"
                    >
                        <i class="hgi-stroke hgi-user"></i>&nbsp;
                        {{ $t("individualAccount") }}
                    </router-link>
                </div>
                <div class="">
                    <router-link
                        :to="{ name: 'companyLogin' }"
                        :class="[{ 'active-button-nav': currentRoute === 'companyLogin'},'text-text-default-color','line-height-24','font-16','nafith-space-gap-0']"
                    >
                        <i class="hgi-stroke hgi-building-03"></i>&nbsp;
                        {{ $t("companiesAccount") }}
                    </router-link>
                </div>
            </div>
            <NavDropdown v-else></NavDropdown>
        </nav>
    </header>
    <header v-else class="desktop-header logged-in-header">
        <div class="header-data">
            <div>
                <div class="section-title">
                    <i class="icon-sanads"></i>
                    <template v-for="(route, index) in returnFirstItem" :key="index">
                        <h2 v-if="route?.meta?.title">{{ route?.meta?.title }}</h2>

                    </template>
                </div>
                <nav
                    style="--bs-breadcrumb-divider: &quot;>&quot;"
                    aria-label="breadcrumb"
                    class="breadcrumb"
                >
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/home">{{ $t("home") }}</router-link>
                        </li>
                        <template v-for="(route, index) in $route.matched" :key="index">
                            <li class="breadcrumb-item active" v-if="route?.meta?.title">
                                <span>{{ route?.meta?.title }}</span>
                            </li>
                        </template>
                    </ol>
                </nav>
            </div>
            <div
                class="navbar m-0 p-0"
                v-if="!user.isAuthenticated && $route?.name?.includes('companyOnboarding')"
            >
                <!-- <div class="login-options">
                    <div class="nav-item">
                        <router-link
                            :to="{ name: 'individualLogin' }"
                            class="nav-link nav-btn d-inline-block btn-warning individual-user-btn"
                        >
                            <i class="hgi-stroke hgi-user"></i>&nbsp;
                            {{ $t("individualAccount") }}
                        </router-link>
                    </div>
                    <div class="nav-item">
                        <router-link
                            :to="{ name: 'companyLogin' }"
                            class="nav-link nav-btn d-inline-block company-user-btn mr-2"
                        >
                            <i class="hgi-stroke hgi-building-03"></i>&nbsp;
                            {{ $t("companiesAccount") }}
                        </router-link>
                    </div>
                </div> -->
                <div class="hide-in-mobile nafith-display-none-500 nafith-space-gap-20" v-if="!user.isAuthenticated">
                <div class="">
                    <router-link
                        :to="{ name: 'individualLogin' }"
                        :class="[{'active-button-nav': currentRoute === 'individualLogin'},'text-text-default-color','line-height-24','font-16','nafith-space-gap-0']"
                    >
                        <i class="hgi-stroke hgi-user"></i>&nbsp;
                        {{ $t("individualAccount") }}
                    </router-link>
                </div>
                <div class="">
                    <router-link
                        :to="{ name: 'companyLogin' }"
                        :class="[{ 'active-button-nav': currentRoute === 'companyLogin'},'text-text-default-color','line-height-24','font-16','nafith-space-gap-0']"
                    >
                        <i class="hgi-stroke hgi-building-03"></i>&nbsp;
                        {{ $t("companiesAccount") }}
                    </router-link>
                </div>
            </div>
            </div>
            <NavDropdown v-else></NavDropdown>
        </div>
        <slot name="steps"></slot>
    </header>
</template>

<script>
import NavDropdown from "@/components/layout/NavDropdown.vue"
export default {
    name: "Header",
    components: {
        NavDropdown
    },
    data() {
        return {
            isScrolledDown: false,
            isMobile: false
        }
    },
    methods: {
        handleScroll() {
            this.isScrolledDown = document.documentElement.scrollTop > 0
        }
    },
    computed: {
        user() {
            return this.$store.getters
        },
        isPortalRoute() {
            return this.$route.name?.startsWith("portal")
        },
        menuItems() {
            return this.$store.getters.getMenuItems
        },
        returnFirstItem() {
            if (this.$route?.matched?.length) {
                return [this.$route.matched[0]]
            }
            return []
        },
        currentRoute() {
            return this.$route.name
        }


    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll)

        if (window.innerWidth <= 600) { this.isMobile = true } else { this.isMobile = false }
        
        window.addEventListener("resize", () => {
            if (window.innerWidth <= 600) { this.isMobile = true } else { this.isMobile = false }
        })
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll)
    }
}
</script>
<style lang="scss">
a{
    text-decoration: none !important;
}

</style>
