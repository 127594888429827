import MainService from "@/services/main.service.ts";
import {decodeJwt} from "@/helpers/utility";

export default {
    state: {
        settings: null,
    },
    getters: {
        getSettings: state => state.settings
    },
    mutations: {
        setSettings(state, settings) {
            state.settings = settings;
        },
    },
    actions: {
        async fetchSettings({commit, state}) {
            if (!state.settings) {
                try {
                    const {data: {result: settings}} = await MainService.getSettings();
                    commit("setSettings", decodeJwt(settings));
                } catch (error) {
                    console.error("Error fetching setting:", error);
                }
            }
        },
    },
};