<template>
  <div id="app">
    <Header v-if="(!user.isAuthenticated && !$route?.name?.includes('companyOnboarding')) || (!isPortalRoute && !$route?.name?.includes('companyOnboarding'))"></Header>
    <HeaderMobile v-if="user.isAuthenticated"></HeaderMobile>
    <div class="main-wrapper">
      <!-- <TopBar v-if="showTopBar"></TopBar> -->
      <div v-if="isPortalRoute">
        <div class="main-wrapper-section">
          <div class="d-none d-xl-block">
            <SidebarSection v-if="isPortalRoute"></SidebarSection>
          </div>
          <div :class="[{'mb-xl-5': !showTopBar}]" style="width:100%" >
            <Header v-if="user.isAuthenticated && !$route?.name?.includes('portalSanadCreat')"></Header>
            <router-view></router-view>
          </div>
        </div>
      </div>
      <div v-else>
        <router-view></router-view>
      </div>
    </div>
    <Footer></Footer>
    <Toast :baseZIndex="10000000000000"
           :breakpoints="{'920px': {width: '95%', right: '50%', transform: 'translate(50%, 0)'}}"
    />
    <ZohoSalesIQ></ZohoSalesIQ>
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import HeaderMobile from "@/components/layout/HeaderMobile.vue";
import Footer from "@/components/layout/Footer.vue";
import Toast from "primevue/toast";
import SidebarSection from "@/components/portal/layout/SidebarSection.vue";
import ZohoSalesIQ from "@/components/main/ZohoSalesIQ.vue";
import TopBar from "@/components/portal/layout/TopBar.vue";

export default {
  name: "App",
  components: {
    TopBar,
    ZohoSalesIQ,
    SidebarSection,
    Header,
    HeaderMobile,
    Footer,
    Toast,
  },
  data() {
    return {
      isScrolledDown: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters
    },
    isPortalRoute() {
      return this.$route.name?.startsWith("portal");
    },
    showTopBar() {
      return this.$route?.name === "portalIndexView"
    }
  },
  methods: {
    handleScroll() {
      this.isScrolledDown = document.documentElement.scrollTop > 0;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}

</script>