export const formatNumber = number => {
    return (
        number &&
        Number(number)
            .toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            })
            .replace(/\.00$/, "")
    )
}

export const decodeJwt = jwtToken => {
    try {
        return jwtToken && JSON.parse(window.atob(jwtToken.split(".")[1]))
    } catch (error) {
        console.error("Error decoding JWT:", error)
        return null
    }
}

export const fileToBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = () => {
            const fileName = file.name
            const base64Data = reader.result // Extract base64-encoded data
            const combinedData = `${base64Data},${fileName}`

            resolve(combinedData)
        }

        reader.onerror = error => reject(error)
        reader.readAsDataURL(file)
    })
}
export const formattedDate = date => {
    if (!date) {
        return null
    }
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = (newDate.getMonth() + 1).toString().padStart(2, "0")
    const day = newDate.getDate().toString().padStart(2, "0")
    return `${year}-${month}-${day}`
}
