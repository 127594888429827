import { ref, watch } from "vue";
import { DIRECTIONS, STORAGE_NAMES } from "./enum";
export default function useLanguageSwitcher() {
  const root = ref(document.documentElement);
  const isStyleLoaded = ref(false);
  /* 
    Switch between Languages AR and EN
    Default Language is EN
    */
  let storageDirection = localStorage.getItem(STORAGE_NAMES.direction); // null: for first time only
  const direction = ref(storageDirection || DIRECTIONS.rtl);
  if (direction.value == DIRECTIONS.ltr) {
    root.value.setAttribute("dir", DIRECTIONS.ltr);
    import(
      /* webpackChunkName: "main-ltr" */ "../../assets/sass/main-ltr.scss"
    ).then(() => {
      isStyleLoaded.value = true;
    });
  } else {
    root.value.setAttribute("dir", DIRECTIONS.rtl);
    import(
      /* webpackChunkName: "main-rtl" */ "../../assets/sass/main-rtl.scss"
    ).then(() => {
      isStyleLoaded.value = true;
    });
  }
  const switchLanguage = () => {
    if (direction.value == DIRECTIONS.ltr) direction.value = DIRECTIONS.rtl;
    else direction.value = DIRECTIONS.ltr;
  };
  watch(direction, () => {
    console.log("direction changed");
    if (direction.value == DIRECTIONS.rtl)
      localStorage.setItem(STORAGE_NAMES.direction, DIRECTIONS.rtl);
    else localStorage.setItem(STORAGE_NAMES.direction, DIRECTIONS.ltr);
    location.reload();
  });
  return {
    switchLanguage,
    direction,
    isStyleLoaded,
  };
}
