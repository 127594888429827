import { createApp } from "vue"
import router from "@/router"
import PrimeVue from "primevue/config"
import "@/assets/fonts/cairo/font.css"
import "bootstrap/dist/js/bootstrap"
import "primeicons/primeicons.css"
import "primevue/resources/themes/lara-light-teal/theme.css"
import "primevue/resources/primevue.min.css"
import { languageSwitcher } from "./plugins"
import Calendar from "@/components/custom/calendar"
import axios from "axios"
import Card from "primevue/card"
import Badge from "primevue/badge"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import InputText from "primevue/inputtext"
import Textarea from "primevue/textarea"
import InputNumber from "primevue/inputnumber"
import Checkbox from "primevue/checkbox"
import RadioButton from "primevue/radiobutton"
import Dropdown from "primevue/dropdown"
import DataTable from "primevue/datatable"
import Column from "primevue/column"
import ColumnGroup from "primevue/columngroup"
import Row from "primevue/row"
import Paginator from "primevue/paginator"
import Carousel from "primevue/carousel"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import ConfirmDialog from "primevue/confirmdialog"
import Dialog from "primevue/dialog"
import Menu from "primevue/menu"
import Divider from "primevue/divider"
import ConfirmationService from "primevue/confirmationservice"
import Chart from "primevue/chart"
import ToastService from "primevue/toastservice"
import Message from "primevue/message"
import InlineMessage from "primevue/inlinemessage"
import Sidebar from "primevue/sidebar"
import Tooltip from "primevue/tooltip"
import SplitButton from "primevue/splitbutton"
import Button from "primevue/button"
import FileUpload from "primevue/fileupload"
import { ToastSeverity } from "primevue/api"
import TriStateCheckbox from "primevue/tristatecheckbox"
import Skeleton from "primevue/skeleton"
import ProgressBar from "primevue/progressbar"
import Avatar from "primevue/avatar"
import AccountService, { refreshTokenEndpoint } from "@/services/account.service.ts"
import Steps from "primevue/steps"
import { formatNumber } from "@/helpers/utility"
import TabMenu from "primevue/tabmenu"
import store from "./store"
import App from "@/App.vue"
import Panel from "primevue/panel"
import MultiSelect from "primevue/multiselect"
import Translation from "./services/translations"
import { HandleEnums } from "@/mixins/HandleEnums"
import mitt from "mitt"
import { days } from "@/enums"
const weekDays = days()
const emitter = mitt()
const i18n = Translation.getTranslations()
// Axios configurations
axios.defaults.withCredentials = true

axios.defaults.baseURL = `${import.meta.env.VITE_BACKEND_BASE_URL}/api/backend`
axios.interceptors.request.use(
    config => {
        const accessToken = store.getters.getAccessToken
        if (accessToken && config.baseURL.includes(import.meta.env.VITE_BACKEND_BASE_URL)) {
            config.headers.Authorization = `JWT ${accessToken}`
        }

        // Add Accept-Language header if active language is available in local storage
        const activeLanguage = localStorage.getItem("language") || "ar"
        if (activeLanguage) {
            config.headers["Accept-Language"] = activeLanguage
        }

        return config
    },
    error => Promise.reject(error)
)
axios.interceptors.response.use(
    response => response,
    async error => {
        const {
            config: originalRequest,
            response: { status, data }
        } = error

        const handleError = (severity, detail) => {
            app.config.globalProperties.$toast.add({ severity, detail, life: 5000 })
        }

        const handleBlobResponse = async () => {
            try {
                const jsonData = JSON.parse(await data.text())
                const errorMessage =
                    jsonData?.result || app.config.globalProperties.$t("systemError")
                handleError(ToastSeverity.ERROR, errorMessage)
                return Promise.reject(
                    new Error(
                        JSON.stringify({
                            ...error,
                            response: { ...error.response, data: jsonData }
                        })
                    )
                )
            } catch {
                handleError(
                    ToastSeverity.ERROR,
                    app.config.globalProperties.$t("systemError")
                )
                return Promise.reject(
                    new Error("An error occurred while processing the Blob response.")
                )
            }
        }

        const handle401Error = async () => {
            originalRequest._retry = true

            if (originalRequest.url === AccountService.endpoint(refreshTokenEndpoint)) {
                throw new Error("Session expired.")
            }

            const refreshToken = store.getters.getRefreshToken
            if (!refreshToken) {
                return
            }

            try {
                const {
                    data: {
                        result: { access: newAccessToken, user }
                    }
                } = await AccountService.refreshToken(refreshToken)
                if (newAccessToken) {
                    await store.dispatch("setAccessToken", newAccessToken)
                    await store.dispatch("setUser", user)
                    originalRequest.headers.Authorization = `JWT ${newAccessToken}`
                    return axios.request(originalRequest)
                }
            } catch (err) {
                await store.dispatch("logout")
                localStorage.setItem("sessionExpired", "true")
                await router.push({ name: "individualLogin" })
                return Promise.reject(err)
            }
        }

        switch (status) {
        case 500:
            data instanceof Blob
                ? await handleBlobResponse()
                : handleError(
                    ToastSeverity.ERROR,
                    data?.result || app.config.globalProperties.$t("systemError")
                )
            return Promise.reject(error)
        case 409:
            handleError(ToastSeverity.INFO, data?.result)
            return Promise.reject(error)
        case 403:
            await router.push({ name: "portalUnauthorized" })
            return Promise.reject(error)
        case 401:
            if (!originalRequest._retry) {
                return handle401Error()
            }
            return Promise.reject(error)
        default:
            return Promise.reject(error)
        }
    }
)

const app = createApp(App)

// Apply the mixin
app.mixin(HandleEnums)
// Third-party plugin configurations

app.use(PrimeVue, {
    locale: {
        dayNamesShort: weekDays,
        dayNamesMin: weekDays,
        firstDayOfWeek: 0,
        fileSizeTypes: ["image", "pdf", "xls"]
    }
})
app.use(ToastService)
app.use(ConfirmationService)
app.use(languageSwitcher)
app.use(i18n)

// Register components globally
app.component("Avatar", Avatar)
app.directive("tooltip", Tooltip)
app.component("Button", Button)
app.component("Skeleton", Skeleton)
app.component("ProgressBar", ProgressBar)
app.component("TriStateCheckbox", TriStateCheckbox)
app.component("SplitButton", SplitButton)
app.component("Card", Card)
app.component("Badge", Badge)
app.component("TabView", TabView)
app.component("TabPanel", TabPanel)
app.component("TabMenu", TabMenu)
app.component("InputText", InputText)
app.component("Textarea", Textarea)
app.component("InputNumber", InputNumber)
app.component("Checkbox", Checkbox)
app.component("RadioButton", RadioButton)
app.component("Dropdown", Dropdown)
app.component("DataTable", DataTable)
app.component("Column", Column)
app.component("ColumnGroup", ColumnGroup)
app.component("Row", Row)
app.component("Paginator", Paginator)
app.component("Carousel", Carousel)
app.component("Accordion", Accordion)
app.component("AccordionTab", AccordionTab)
app.component("ConfirmDialog", ConfirmDialog)
app.component("Dialog", Dialog)
app.component("Menu", Menu)
app.component("Divider", Divider)
app.component("Sidebar", Sidebar)
app.component("Message", Message)
app.component("InlineMessage", InlineMessage)
app.component("ChartCanvas", Chart)
app.component("FileUpload", FileUpload)
app.component("Steps", Steps)
app.component("Calendar", Calendar)
app.component("Panel", Panel)
app.component("MultiSelect", MultiSelect)

// Register global properties - filters
app.config.globalProperties.$filters = {
    formatNumber
}

// Set up store and router
app.provide("emitter", emitter).use(store).use(router).mount("#app")
