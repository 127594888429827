<template>
    <header class="mobile-header">
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
                <div class="navbar-mobile-header">
                    <NavDropdown></NavDropdown>
                    <template v-for="(menuItem) in menuItems">
                        <p v-if="menuItem.url && $route.name?.includes(menuItem.url)">{{ menuItem.label }}</p>
                    </template>
                    <button 
                        class="navbar-toggler" 
                        data-bs-toggle="collapse" 
                        data-bs-target="#navbarSupportedContent" 
                        aria-controls="navbarSupportedContent" 
                        aria-label="Toggle navigation"
                        aria-expanded="false"
                        type="button" 
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li
                            v-for="(menuItem, index) in menuItems"
                            :key="index"
                            :class="{ active: menuItem.url && $route.name?.includes(menuItem.url) }"
                        >
                            <router-link
                                :to="{ name: menuItem?.url }"
                                @click.prevent="sidebarVisible = false"
                            >
                                <span class="link-icon"><i :class="`hgi-stroke hgi-${menuItem.icon}`"></i></span>
                                <span class="link-text">{{ menuItem.label }}</span>
                                <span v-if="menuItem?.badge" class="badge text-warning notification">{{
                                    menuItem.badge
                                }}</span>
                            </router-link>
                        </li>
                    </ul>
                    <div class="mb-3">
                        <router-link
                            class="button primary fill lg create"
                            :to="{ name: 'portalSanadCreat' }"
                            v-if="showSanadCreationButton"
                        >
                            <i class="hgi-stroke hgi-plus-sign"></i>
                            <span>{{ $t("createSanad") }}</span>
                        </router-link>
                        <router-link
                            v-if="isLawyerCompany"
                            class="button primary secondary-solid lg manage"
                            :to="{ name: 'portalContract' }"
                        >
                            <span>{{ $t("contractManagement") }}</span>
                            <i class="hgi-stroke hgi-share-03"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import UserAuthMixin from "@/mixins/UserAuthMixin"
import NavDropdown from "@/components/layout/NavDropdown.vue"
import { userTypes } from "@/enums"

export default {
    name: "HeaderMobile",
    mixins: [UserAuthMixin],
    components: {
        NavDropdown
    },
    inject: ["emitter"],
    data() {
        return {
            sidebarVisible: false,
            menuItems: []
        }
    },
    async created() {
        await this.fetchMenuItems()
    },
    methods: {
        async fetchMenuItems() {
            await this.$store.dispatch("fetchMenuItems")
            this.menuItems = this.$store.getters.getMenuItems
        }
    },
    computed: {
        showSanadCreationButton() {
            const { type, company, permissions } = this.user
            return (
                type === userTypes.INDIVIDUAL ||
                (type === userTypes.COMPANY &&
                    (company?.is_owner || permissions?.includes("sanad.create_sanad")))
            )
        }
    },
    mounted() {
        this.emitter.on("fetchMenuItems", async () => {
            await this.fetchMenuItems()
        })
    }
}
</script>