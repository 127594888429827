export const userTypes = {
    INDIVIDUAL: "individual",
    COMPANY: "company",
    ADMIN: "admin",
    INTEGRATOR: "integrator"
}

export const companyDashboardTypes = {
    OWN: "own",
    COMPANY: "company"
}

export const otpTypes = {
    RESET_PASSWORD: "reset_password",
    CHANGE_PASSWORD: "change_password",
    DEBTOR_OTP: "debtor_otp",
    SHOW_SECRET_KEYS: "show_secret_keys",
    CHANGE_PHONE_NUMBER: "change_phone_number",
    VERIFY_PHONE_NUMBER: "verify_phone_number",
    CLOSE_SANAD: "close_sanad",
    COMPANY_LOGIN: "company_login",
    ADMIN_LOGIN: "admin_login"
}

export const payThroughOptions = {
    PACKAGE: "package",
    PAYMENT_GATEWAY: "payment_gateway"
}

export const sanadStatusOptions = {
    STATUS_DRAFT: "draft",
    STATUS_PENDING: "pending",
    STATUS_APPROVED: "approved",
    STATUS_CANCELED_BY_CREDITOR: "cancelled_by_creditor",
    STATUS_REJECTED_BY_DEBTOR: "rejected_by_debtor",
    STATUS_NO_RESPONSE: "no_response",
    STATUS_CLOSED: "closed",
    STATUS_EXPIRED_DUE_DATE: "expired_due_date",
    STATUS_PAYMENT_IS_DUE: "payment_is_due",
    STATUS_LEGALLY_REJECTED: "legally_rejected",
    STATUS_PARTIALLY_PAID: "partially_paid"
}

export const sanadCreationSteps = {
    SANAD_SHAPE: "sanad-shape",
    SANAD_TYPE: "sanad-type",
    SANAD_INFO: "sanad-info",
    DEBTOR_INFO: "debtor-info",
    DEBTOR_OTP: "debtor-otp",
    CONFIRM_SANAD_DETAILS: "confirm-sanad-details",
    SANAD_PAYMENT: "sanad-payment"
}

export const companyRegistrationSteps = {
    COMMERCIAL_NUMBER: "commercial-number",
    COMPANY_GENERAL_DETAILS: "company-general-details",
    COMPANY_DELEGATOR_DETAILS: "company-delegator-details",
    COMPANY_PAYMENT_CHOOSE: "company-payment-choose",
    COMPANY_FILE_UPLOAD: "company-file-upload",
    COMPANY_REVIEW_REQUEST: "company-review-request"
}

export const statusOptions = {
    SUCCESS: "success",
    FAILED: "failed",
    APPROVED: "approved",
    PENDING: "pending",
    REJECTED: "rejected",
    DRAFT: "draft",
    CANCELED: "canceled",
    NO_RESPONSE: "no_response"
}

export const languageOptions = {
    ARABIC: "arabic",
    ENGLISH: "english"
}

export const otpChannels = {
    SMS: "sms",
    EMAIL: "mail"
}

export const sanadCategoryOptions = {
    pendingSanads: "pending_sanads",
    creditSanads: "credit_sanads",
    creditOwnSanads: "credit_own_sanads",
    debitSanads: "debit_sanads"
}

export const dueType = {
    DATE: "date",
    UPON_REQUEST: "upon request"
}

export const sanadType = {
    SINGLE: "single",
    MULTIPLE: "multiple"
}
export const roleOptions = {
    MAILING_LIST: "mailing_list",
    DEFAULT: ""
}
export const employeeTypes = {
    ACTIVE: "active",
    INACTIVE: "inactive",
    MAILING_LIST: "mailing_list"
}
export const userManagementLisTermType = {
    TERM_TYPE_ID: "employee_id",
    TERM_TYPE_NAME: "name",
    TERM_TYPE_EMAIL: "email",
    TERM_TYPE_PHONE: "phone_number"
}
export const credentialSentBy = {
    EMAIL: "email",
    SMS: "sms"
}
export const callBackHistoryOptions = {
    WORK_IN_PROGRESS: "notification_in_progress",
    COMMUNICATION_DONE: "notified",
    COMMUNICATION_FAILED: "notification_failed",
    TRANSMISSION_FAILED: "new"
}

export const days = () => {
    return localStorage.getItem("language") === "en"
        ? ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        : ["احد", "اثنين", "ثلاثاء", "اربعاء", "خميس", "جمعة", "سبت"]
}

export const InvoiceStatuses = {
    PAID: "paid",
    UN_PAID: "unpaid",
    EXPIRED: "expired",
    ALL: "all"
}
export const subscriptionStatuses = {
    PENDING: "pending",
    ACTIVE: "active",
    FINISHED: "finished",
    ALL: "all"
}
export const apiIntegrationSubscriptionOptions = {
    SUBSCRIBED: "subscribed",
    UNSUBSCRIBED: "unsubscribed",
    PENDING: "pending"
}
export const transactionTypes = {
    CD: "CD",
    DB: "DB",
    RF: "RF"
}
export const paymentMethods = {
    BANK_TRANSFER: "bank_transfer",
    SADAD: "sadad"
}
export const companyOnboardingStatusOptions = {
    SUCCESS: "success",
    FAILED: "failed",
    APPROVED: "approved",
    PENDING: "pending",
    REJECTED: "rejected",
    DRAFT: "draft",
    CANCELED: "canceled",
    NO_RESPONSE: "no_response",
    INITIAL_INVOICE_CREATED: "status_initial_invoice_created"
}

export const reportTypes = {
    SANAD_DETAILED: "sanad_detailed_report",
    FINANCIAL_CONSUMPTION: "financial_consumption_report"
}
export const reportStatusOptions = {
    NEW: "new",
    PROCESSING: "processing",
    READY: "ready",
    FAILED: "failed",
    NOT_AVAILABLE: "not_available"
}

export const autoCompleteIdentifiers = {
    COMPANY_USER_GROUP: "company_user_group",
    USER: "user"
}

export const companyBusinessTypes = {
    GENERAL: "0",
    LAWYER: "1"
}

export const reportFormats = {
    PDF: "pdf",
    CSV: "csv"
}

export const consumptionReportTypes = {
    SANAD: "sanad",
    PACKAGE: "package"
}
