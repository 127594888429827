<template>
    <div class="header-card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-3 m-1">
                    <small class="text-orange"></small>
                    <i aria-hidden="true" class="hgi-stroke hgi-user text-orange"></i>
                    &nbsp;
                    <small class="item">{{ user.full_name }}</small>
                </div>
                <div class="col-md-3 m-1">
                    <small class="text-orange"></small>
                    <i aria-hidden="true" class="hgi-stroke hgi-id text-orange"></i>
                    &nbsp;
                    <small class="item">{{
                        isCompany ? company?.commercial_registration_id : user.username
                    }}</small>
                </div>
                <div class="col-md-3 m-1" v-if="isCompanyUser">
                    <i aria-hidden="true" class="hgi-stroke hgi-building-03 text-orange"></i>
                    &nbsp;
                    <small class="item">{{ company?.name }}</small>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 m-1" v-if="!isIndividualUser">
                    <i aria-hidden="true" class="hgi-stroke hgi-sent text-orange"></i>
                    &nbsp;
                    <small class="item">{{ user.email }}</small>
                </div>
                <div class="col-md-3 m-1">
                    <i aria-hidden="true" class="hgi-stroke hgi-call text-orange"></i>
                    &nbsp;
                    <small class="item">{{ user.phone_number }}</small>
                </div>
                <div class="col-md-3 m-1" v-if="!isCompany">
                    <i aria-hidden="true" class="hgi-stroke hgi-home-05 text-orange"></i>
                    &nbsp;
                    <small class="item">{{ $t("ksa") }}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserAuthMixin from "@/mixins/UserAuthMixin"

export default {
    name: "TopBar",
    mixins: [UserAuthMixin]
}
</script>
