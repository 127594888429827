import BaseService from "./BaseService.js";

const dashboardAppEndpoint = "/dashboard"
const dashboardDataEndpoint = "/dashboard_data"
export default class DashboardService extends BaseService {
    static appEndpoint = dashboardAppEndpoint

    static getInsightData(userType, queryParams = {}) {
        return this.request("get",
            this.endpoint(`${dashboardDataEndpoint}/${userType}_insights_data/`),
            {params: {...queryParams}}
        );
    }

    static getChartData(type, queryParams = {}) {
        return this.request(
            "get",
            this.endpoint(`${dashboardDataEndpoint}/${type}/`),
            {params: queryParams}
        );
    }
}