import MainService from "@/services/main.service.ts"

export default {
    state: {
        menuItems: []
    },
    getters: {
        getMenuItems: state => state.menuItems,
        getFirstMenuItemRouteName: state => {
            return state.menuItems?.[0]?.url
        }
    },
    mutations: {
        setMenuItems(state, menuItems) {
            state.menuItems = menuItems
        },
        clearMenuItems(state) {
            state.menuItems = []
        }
    },
    actions: {
        async fetchMenuItems({ commit, state }, forceFetch = false) {
            if (!forceFetch && !!state.menuItems?.[0]) {
                return
            } // if not forceFetch and menuItems already fetched, do nothing

            try {
                const response = await MainService.getMenuItems()
                commit("setMenuItems", Object.values(response.data?.result))
            } catch (error) {
                console.error(error)
            }
        },
        clearMenuItems({ commit }) {
            commit("clearMenuItems")
        }
    }
}
