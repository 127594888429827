<template>
    <div class="nav-item dropdown nav-avatar ml-5 show">
        <a
            id="navbarDropdown"
            aria-expanded="true"
            aria-haspopup="true"
            class="nav-link dropdown-toggle"
            role="button"
            @click.prevent="toggle"
        >
            <Avatar
                class="m-2"
                :icon="`hgi-stroke hgi-${isCompany ? 'building-03' : 'user'}`"
                shape="circle"
            />
            <span class="nav-avatar-text d-none d-md-inline">
                {{ user.full_name }}
            </span>
        </a>
        <Menu
            id="overlay_menu"
            ref="menu"
            :model="menuItems"
            :popup="true"
            :pt="{
                submenuHeader: { class: 'text-surface-900' },
                action: ({ props, state, context }) => ({
                    class: context.focused ? 'bg-primary-400' : undefined,
                }),
            }"
            class="text-right nafith-nav-dropdown-menu"
        >
            <template #item="{ label, item, props }">
                <div
                    style="padding: 0.75rem 1.25rem"
                    v-if="item.route?.query?.action === otpTypes.CHANGE_PASSWORD"
                    @click.prevent="showModal(item)"
                >
                    <span v-bind="props.icon" />
                    <span v-bind="props.label">{{ label }}</span>
                </div>
                <router-link
                    v-else-if="item.route"
                    v-slot="routerProps"
                    :to="item.route"
                    custom
                >
                    <a
                        :href="routerProps.href"
                        v-bind="props.action"
                        @click="routerProps.navigate"
                    >
                        <span v-bind="props.icon" />
                        <span v-bind="props.label">{{ label }}</span>
                    </a>
                </router-link>
                <a v-else :href="item.url" v-bind="props.action">
                    <span v-bind="props.icon" />
                    <span v-bind="props.label">{{ label }}</span>
                </a>
            </template>
        </Menu>
        <Dialog
            :style="{ maxWidth: '600px' }"
            :draggable="false"
            v-model:visible="showConfirmationDialog"
            base-z-index="10000000"
            class="text-right nafith-dialog"
            modal
        >
        {{$t('ConfirmSentChangePasswordMessage')}}
            <template #footer>
                <Button
                    icon="hgi-stroke hgi-cancel-01 mx-1"
                    :label="$t('no')"
                    severity="danger"
                    text
                    @click.prevent="cancel"
                    class="height-40"

                />
                <Button
                    :pt="{ loadingIcon: { class: 'mx-1' } }"
                    icon="hgi-stroke hgi-tick-02  mx-1"
                    :label="$t('yes')"
                    severity="success"
                    @click.prevent="redirect"
                    class="button primary fill height-40"

                />
            </template>
        </Dialog>
    </div>
</template>

<script>
import UserAuthMixin from "@/mixins/UserAuthMixin"

import { otpTypes } from "@/enums"

export default {
    name: "NavDropdown",
    mixins: [UserAuthMixin],
    data() {
        return {
            showConfirmationDialog: false,
            selectedItem: null
        }
    },
    computed: {
        menuItems() {
            return this.menuItemsDefinition.filter(item => item?.render !== false)
        },
        menuItemsDefinition() {
            return [
                {
                    label: this.$t("mobileNumberChange"),
                    icon: "hgi-stroke hgi-call mx-1",
                    route: {
                        name: "generateOTP",
                        query: { action: otpTypes.CHANGE_PHONE_NUMBER }
                    }
                },
                { separator: true },
                {
                    label: this.$t("changePassword"),
                    icon: "hgi-stroke hgi-square-lock-01 mx-1",
                    route: {
                        name: "generateOTP",
                        query: { action: otpTypes.CHANGE_PASSWORD }
                    },
                    render: !this.isIndividualUser && !this.user?.is_password_expired
                },
                {
                    separator: true,
                    render: !this.isIndividualUser && !this.user?.is_password_expired
                },
                {
                    label: this.$t("logout"),
                    icon: "hgi-stroke hgi-logout-02 mx-1",
                    route: { name: "logout" }
                }
            ]
        },
        otpTypes() {
            return otpTypes
        }
    },
    methods: {
        toggle(event) {
            this.$refs.menu.toggle(event)
        },
        showModal(item) {
            this.selectedItem = item
            this.showConfirmationDialog = true
        },
        cancel() {
            this.showConfirmationDialog = false
        },
        redirect() {
            this.showConfirmationDialog = false
            if (this.selectedItem && this.selectedItem.route) {
                this.$router.push(this.selectedItem.route)
            }
        }
    }
}
</script>
