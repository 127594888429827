import axios from "axios";

export default class BaseService {
    static appEndpoint;

    static request(method, endpoint, data = null, configs = null) {
        return axios[method](endpoint, data, configs);
    }

    static endpoint(path) {
        return `${this.appEndpoint}${path}`;
    }
}