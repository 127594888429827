export default {
    state: {
        sanads: {},
    },
    getters: {
        getSanadType: (state) => (uuid) => state.sanads[uuid]?.sanadTypeRes || {},
        getSanadShape: (state) => (uuid) => state.sanads[uuid]?.sanadShapeRes || {},
        getSanadInfo: (state) => (uuid) => state.sanads[uuid]?.sanadInfoRes || {},
        getSanadDebtorInfo: (state) => (uuid) => state.sanads[uuid]?.sanadDebtorInfoRes || {},
        getConfirmSanadDetails: (state) => (uuid) => state.sanads[uuid]?.confirmSanadDetailsRes || {},
    },
    actions: {
        handleSanadTypeData({ commit }, { uuid, data }) {
            commit("setSanadTypeData", { uuid, data });
        },
        handleSanadShapeData({ commit }, { uuid, data }) {
            commit("setSanadShapeData", { uuid, data });
        },
        handleSanadInfoData({ commit }, { uuid, data }) {
            commit("setSanadInfoData", { uuid, data });
        },
        handleSanadDebtorInfoData({ commit }, { uuid, data }) {
            commit("setSanadDebtorInfoData", { uuid, data });
        },
        handleConfirmSanadDetailsData({ commit }, { uuid, data }) {
            commit("setConfirmSanadDetailsData", { uuid, data });
        },
    },
    mutations: {
        setSanadTypeData(state, { uuid, data }) {
            if (!state.sanads[uuid]) state.sanads[uuid] = {};
            state.sanads[uuid].sanadTypeRes = data;
            state.sanads[uuid].confirmSanadDetailsRes = {};
            state.sanads[uuid].sanadInfoRes = {};
        },
        setSanadShapeData(state, { uuid, data }) {
            if (!state.sanads[uuid]) state.sanads[uuid] = {};
            state.sanads[uuid].sanadShapeRes = data;
            state.sanads[uuid].confirmSanadDetailsRes = {};
            state.sanads[uuid].sanadInfoRes = {};
            state.sanads[uuid].sanadDebtorInfoRes = {};
        },
        setSanadInfoData(state, { uuid, data }) {
            if (!state.sanads[uuid]) state.sanads[uuid] = {};
            state.sanads[uuid].sanadInfoRes = data;
            state.sanads[uuid].confirmSanadDetailsRes = {};
        },
        setSanadDebtorInfoData(state, { uuid, data }) {
            if (!state.sanads[uuid]) state.sanads[uuid] = {};
            state.sanads[uuid].sanadDebtorInfoRes = data;
            state.sanads[uuid].confirmSanadDetailsRes = {};
        },
        setConfirmSanadDetailsData(state, { uuid, data }) {
            if (!state.sanads[uuid]) state.sanads[uuid] = {};
            state.sanads[uuid].confirmSanadDetailsRes = data;
        },
    },
};
