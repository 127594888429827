import { companyBusinessTypes, userTypes } from "@/enums"
import { mapGetters, useStore } from "vuex"
import { computed } from "vue"

export default {
    computed: {
        ...mapGetters(["isAuthenticated", "getUser", "hasPermissions", "hasPermission"]),
        user() {
            return this.getUser
        },
        company() {
            return this.user?.company
        },
        isIndividualUser() {
            return this.user?.type === userTypes.INDIVIDUAL
        },
        isCompanyUser() {
            return this.user?.type === userTypes.COMPANY && !this.user?.company?.is_owner
        },
        isCompany() {
            return this.user?.type === userTypes.COMPANY
        },
        isLawyerCompany() {
            return this.user?.company?.business_type === companyBusinessTypes.LAWYER
        },
        isCompanyOwner() {
            return this.user?.type === userTypes.COMPANY && this.user?.company?.is_owner
        },
        isSuperAdmin() {
            return this.user?.type === userTypes.ADMIN
        },
        userHasPermissions() {
            return permissions => {
                return this.hasPermissions(permissions)
            }
        },
        userHasPermission() {
            return permission => {
                const ModifiedPermission = Array.isArray(permission)
                    ? permission
                    : [permission]
                return this.hasPermission(ModifiedPermission)
            }
        }
    }
}

export const useAuthMixin = () => {
    const store = useStore()
    const user = computed(() => store.getters.getUser)
    const company = computed(() => user.value?.company)
    const isIndividualUser = computed(() => user.value?.type === userTypes.INDIVIDUAL)
    const isCompanyUser = computed(
        () => user.value?.type === userTypes.COMPANY && !user.value?.company?.is_owner
    )
    const isCompany = computed(() => user.value?.type === userTypes.COMPANY)
    const isCompanyOwner = computed(
        () => user.value?.type === userTypes.COMPANY && user.value?.company?.is_owner
    )
    const isSuperAdmin = computed(() => user.value?.type === userTypes.ADMIN)
    const userHasPermissions = computed(() => permissions => {
        return store.getters.hasPermissions(permissions)
    })

    const userHasPermission = computed(() => permission => {
        const ModifiedPermission = Array.isArray(permission) ? permission : [permission]
        return store.getters.hasPermission(ModifiedPermission)
    })
    return {
        user,
        company,
        isIndividualUser,
        isCompanyUser,
        isCompany,
        isCompanyOwner,
        isSuperAdmin,
        userHasPermissions,
        userHasPermission
    }
}
