import { createI18n } from "vue-i18n"
import AR from "../translations/ar.json"
import EN from "../translations/en.json"

class Translation {
  static getTranslations () {
    return new createI18n({
      legacy: false,
      locale: localStorage.getItem("language") || "ar", // Default locale
      fallbackLocale: "en",
      messages: {
        en: EN,
        ar: AR
      }
    })
  }
}
export default Translation
