<template></template>
<script>
export default {
  name: "ZohoSalesIQ",
  methods: {
    // Check if ZohoSalesIQ script element exists, then load or create accordingly
    loadZohoSalesIQScript() {
      const scriptElement = document.querySelector('[data-id="zsalesiq"]');

      if (!scriptElement) {
        this.createZohoSalesIQScript();
      } else {
        this.showElement(scriptElement);
      }
    },
    // Dynamically create ZohoSalesIQ script and append it to the DOM
    createZohoSalesIQScript() {
      // Ensure the global ZohoSalesIQ object is defined
      window.$zoho = window.$zoho || {};
      window.$zoho.salesiq = window.$zoho.salesiq || {
        widgetcode: "siq82e5302f6f3b9487dad79dfbda2c09364bd7f1da3265427a9405e5f2a06fb470",
        values: {},
        ready: function () {
        },
      };

      // Create script element
      const newScriptElement = document.createElement("script");
      newScriptElement.type = "text/javascript";
      newScriptElement.id = "zsiqscript";
      newScriptElement.defer = true;
      newScriptElement.src = "https://salesiq.zohopublic.com/widget";

      // Insert the script as the first script element
      const firstScriptElement = document.getElementsByTagName("script")[0];
      firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement);

      // Show the newly created script
      this.showElement(newScriptElement);
    },
    hideZohoSalesIQElement() {
      const scriptElement = document.querySelector('[data-id="zsalesiq"]');
      if (scriptElement) {
        this.hideElement(scriptElement);
      }
    },
    showElement(element) {
      element.style.display = "block";
    },
    hideElement(element) {
      element.style.display = "none";
    },
  },
  watch: {
    // Watch for route changes and update ZohoSalesIQ visibility
    "$route"(to, from) {
      // Load ZohoSalesIQ script if the route doesn't start with "portal"
      // Hide ZohoSalesIQ element if transitioning from not a "portal" route, "landing pages"
      if (!to.name?.startsWith("portal")) {
        this.loadZohoSalesIQScript();
      } else if (!from.name?.startsWith("portal")) {
        this.hideZohoSalesIQElement();
      }
    },
  }
};
</script>
