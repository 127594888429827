export default {
    state: {
        term: null,
        term_type: null
    },
    getters: {
        getUserManagementState: state => state
    },
    mutations: {
        setUserManagementState(state, payload) {
            state.term = payload.term
            state.term_type = payload.term_type
        },
        resetUserManagementState (state) {
            state.term = null
            state.term_type = null
        }
    }
}
