export default {
    state: {
        uuid: null,
        created_at: null
    },
    getters: {
        getCallbackHistoryState: state => state
    },
    mutations: {
        setCallbackHistoryState(state, payload) {
            state.uuid = payload.uuid
            state.created_at = payload.created_at
        },
        resetCallbackHistoryState (state) {
            state.uuid = null
            state.created_at = null
        }
    }
}
