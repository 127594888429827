import { createRouter, createWebHistory } from "vue-router"
import { companyBusinessTypes, userTypes } from "@/enums"
import store from "@/store"
import ShortUrlRedirect from "@/views/main/ShortUrlRedirect.vue"

import Translation from "@/services/translations"
const i18n = Translation.getTranslations()

const frontendBaseRoute = "/app"
const backendBaseURL = import.meta.env.VITE_BACKEND_BASE_URL

// Lazy-loaded views
const routes = [
    // Short URLs views
    {
        path: "/:urlHash", // Dynamic route to match any URL hash
        redirect: to => ({
            name: "shortUrlRedirect",
            params: { urlHash: to.params.urlHash }
        }),
        meta: {
            title: "shortUrlRedirect"
        }
    },
    {
        path: "/main/:urlHash",
        name: "shortUrlRedirect",
        component: ShortUrlRedirect,
        meta: {
            title: "shortUrlRedirect"
        }
    },
    // Main views
    {
        path: "/",
        name: "landing",
        redirect: "/home",
        meta: {
            title: i18n.global.t("home")
        }
    },
    {
        path: "/home/",
        name: "home",
        component: () => import("../views/main/HomeView.vue"),
        meta: {
            title: i18n.global.t("home")
        }
    },
    {
        path: "/execution-sanads/",
        name: "executionSanads",
        component: () => import("@/views/main/ExecutionSanads.vue"),
        meta: {
            title: i18n.global.t("executionSanads")
        }
    },
    {
        path: "/faq/",
        name: "faq",
        component: () => import("@/views/main/Faq.vue"),
        meta: {
            title: i18n.global.t("faq")
        }
    },
    {
        path: "/contact-us/",
        name: "contactUs",
        component: () => import("@/views/main/ContactUs.vue"),
        meta: {
            title: i18n.global.t("contactUs")
        }
    },
    {
        path: "/verify-email/:uidb64/:token",
        name: "verifyEmail",
        component: () =>
            import("@/views/portal/company/employee_management/VerifyEmail.vue"),
        meta: {
            title: i18n.global.t("verifyEmail")
        }
    },
    // Account views
    {
        path: "/accounts/verify-phone-number/",
        name: "verifyPhoneNumber",
        component: () => import("@/views/main/VerifyPhoneNumber.vue"),
        meta: {
            requiresAuth: false,
            title: i18n.global.t("verifyPhoneNumber")
        }
    },
    {
        path: "/accounts/otp-verification/:token",
        name: "otpVerification",
        component: () => import("@/views/main/OTPVerification.vue"),
        meta: {
            title: i18n.global.t("otpVerification")
        }
    },
    {
        path: "/accounts/change-phone-number/:token",
        name: "changePhoneNumber",
        meta: { requiresAuth: true, title: i18n.global.t("changePhoneNumber") },
        component: () => import("@/views/main/ChangePhoneNumber.vue")
    },
    {
        path: "/accounts/change-password/:token",
        name: "changePassword",
        meta: {
            requiresAuth: true,
            canAccess: ({ type }) => type !== userTypes.INDIVIDUAL,
            title: i18n.global.t("changePassword")
        },
        component: () => import("@/views/main/ChangePassword.vue")
    },
    {
        path: "/accounts/force-change-password/",
        name: "forceChangePassword",
        meta: {
            requiresAuth: true,
            canAccess: ({ type }) => type !== userTypes.INDIVIDUAL,
            title: i18n.global.t("changePassword")
        },
        component: () => import("@/views/main/ForceChangePassword.vue")
    },
    {
        path: "/accounts/generate-otp/",
        name: "generateOTP",
        component: () => import("@/views/main/GenerateOTP.vue"),
        meta: {
            title: i18n.global.t("otpCode")
        }
    },
    {
        path: "/accounts/login/credentials/individual/",
        name: "individualLoginCredentials",
        component: () => import("@/views/auth/IndividualLoginCredentials.vue"),
        meta: {
            requiresAuth: false,
            canAccess: ({ settings }) => !settings?.iam_sso_active,
            title: i18n.global.t("individulLogin")
        }
    },
    {
        path: "/accounts/login/individual/tcc_login/",
        name: "individualMobileRandomCodeVerification",
        component: () => import("@/views/auth/RandomCodeVerification.vue"),
        meta: {
            title: i18n.global.t("individulLogin")
        }
    },
    {
        path: "/accounts/login/individual/show-number/:trans_id/",
        name: "individualShowMobileRandomCode",
        component: () => import("@/views/auth/RandomCodeVerification.vue"),
        props: true,
        meta: {
            title: i18n.global.t("individulLogin")
        }
    },
    {
        path: "/accounts/login/individual/",
        name: "individualLogin",
        component: () => import("@/views/auth/IndividualLoginIAM.vue"),
        meta: { requiresAuth: false, title: i18n.global.t("individulLogin") }
    },
    {
        path: "/accounts/login/company/",
        name: "companyLogin",
        component: () => import("@/views/auth/CompanyLogin.vue"),
        meta: { requiresAuth: false, title: i18n.global.t("companiesLogin") }
    },
    {
        path: "/accounts/logout/",
        name: "logout",
        component: () => import("@/views/auth/Logout.vue"),
        meta: { requiresAuth: true, title: i18n.global.t("logout") }
    },
    {
        path: "/iam-callback/",
        name: "iamCallback",
        component: () => import("@/views/auth/IamCallback.vue"),
        meta: {
            title: i18n.global.t("callbackUrl")
        }
    },
    {
        path: "/company-onboarding/",
        meta: {
            requiresAuth: false,
            title: i18n.global.t("createCompany"),
            canAccess: ({ settings }) => !!settings?.enable_companies_registrations
        },
        redirect: {
            name: "companyOnboardingIndex"
        },
        children: [
            {
                path: "",
                name: "companyOnboardingIndex",
                component: () => import("@/views/main/company_onboarding/Index.vue"),
                meta: {
                    title: i18n.global.t("companyOnBoarding.registrationPaths")
                }
            },
            {
                path: "registration/:step/:uuid?",
                name: "companyOnboarding",
                component: () =>
                    import("@/views/main/company_onboarding/CompanyOnboarding.vue"),
                meta: {
                    title: i18n.global.t("companyOnBoarding.commercialEntities")
                }
            },
            {
                path: "terms-conditions/:uuid?",
                name: "companyOnboardingTermsConditions",
                component: () =>
                    import(
                        "@/views/main/company_onboarding/CompanyOnboardingTermsConditions.vue"
                    ),
                meta: {
                    title: "termAndCondition"
                }
            },
            {
                path: "request-status/:status/:uuid",
                name: "companyOnboardingRequestStatus",
                component: () =>
                    import("@/views/main/company_onboarding/CompanyOnboardingStatus.vue"),
                meta: {
                    title: "companyOnboardingRequestStatus"
                }
            },
            {
                path: "process-terms-conditions-approval/:status/:uuid",
                name: "companyOnboardingProcessTermsConditionsApprovalRejection",
                component: () =>
                    import(
                        "@/views/main/company_onboarding/CompanyOnboardingTermsConditionsProcessApprovalRejection.vue"
                    ),
                meta: {
                    title: "termAndCondition"
                }
            },
            {
                path: "update-company-request/:uuid",
                name: "companyOnboardingProcessUpdateRequest",
                component: () =>
                    import("@/views/main/company_onboarding/PreviewCompanyRequest.vue"),
                meta: {
                    title: "companyOnboardingProcessUpdateRequest"
                }
            }
        ]
    },
    // Portal views
    {
        path: "/portal/",
        name: "portalIndexView",
        component: () => import("@/views/portal/IndexView.vue"),
        beforeEnter: async (to, from, next) => {
            const { type, company, permissions } = store.getters.getUser || {}

            type === userTypes.COMPANY &&
            !company?.is_owner &&
            !permissions?.some(permission =>
                ["sanad.view_own_dashboard", "sanad.view_company_dashboard"].includes(
                    permission
                )
            )
                ? next({
                    name: await store
                        .dispatch("fetchMenuItems")
                        .then(() => store.getters.getFirstMenuItemRouteName)
                }) // ensure menu items are fetched before accessing the 1st menu item
                : type === userTypes.INTEGRATOR
                    ? next({ name: "portalCompanyIntegrator" })
                    : next()
        },
        meta: { requiresAuth: true, title: i18n.global.t("dashboard") }
    },
    {
        path: "/portal/najiz/",
        name: "portalNajiz",
        component: () => import("@/views/portal/Najiz.vue"),
        meta: {
            requiresAuth: true,
            title: i18n.global.t("najiz"),
            canAccess: ({ type }) =>
                [userTypes.INDIVIDUAL, userTypes.COMPANY].includes(type)
        }
    },
    // Portal - account views
    {
        path: "/portal/account/",
        name: "portalAccount",
        meta: {
            title: i18n.global.t("profile"),
            requiresAuth: true,
            canAccess: ({ isSuperAdmin, type, settings, permissions }) =>
                !!settings?.enable_sanad_settlement_feature &&
                !!settings?.enable_iban_verification_feature &&
                (type === userTypes.INDIVIDUAL ||
                    (type === userTypes.ADMIN &&
                        (isSuperAdmin ||
                            permissions?.includes("support.customer_support"))))
        },
        redirect: {
            name: "portalAccountProfile"
        },
        component: () => import("@/views/portal/account/Account.vue"),
        children: [
            {
                path: "/portal/account/profile/",
                name: "portalAccountProfile",
                component: () => import("@/components/portal/account/Profile.vue")
            }
        ]
    },
    // Portal - sanad creation views
    {
        path: "/portal/sanad/creation/",
        name: "portalSanadCreat",
        meta: {
            requiresAuth: true,
            title: i18n.global.t("createSanad"),
            canAccess: ({ type, company, permissions }) =>
                type === userTypes.INDIVIDUAL ||
                (type === userTypes.COMPANY &&
                    (company?.is_owner ||
                        permissions?.some(permission =>
                            ["sanad.create_sanad", "sanad.edit_company_sanad"].includes(
                                permission
                            )
                        )))
        },
        redirect: to => {
            const { type, company } = store.getters.getUser || {}
            const settings = store.getters.getSettings

            if (
                to.name !== "portalBlockedSanadCreation" &&
                (company?.is_blocked_sanad_creation ||
                    !settings?.enable_individual_sanad_creation)
            ) {
                return { name: "portalBlockedSanadCreation" }
            }
            return `/portal/sanad/creation/${type === userTypes.INDIVIDUAL ? "sanad-shape" : "sanad-type"}`
        },
        children: [
            {
                path: ":step/:uuid?",
                name: "portalSanadCreation",
                component: () => import("@/views/portal/sanad/creation/SanadCreation.vue"),
                meta: { requiresAuth: true }
            },
            {
                path: "blocked",
                name: "portalBlockedSanadCreation",
                component: () =>
                    import("@/views/portal/sanad/creation/BlockedSanadCreation.vue"),
                meta: {
                    title: i18n.global.t("blockedSanadCreation"),
                    requiresAuth: true,
                    canAccess: ({ company, settings }) =>
                        company?.is_blocked_sanad_creation ||
                        !settings?.enable_individual_sanad_creation
                }
            }
        ]
    },
    // Sanad approval views (authenticated vs guest)
    {
        path: "/sanad/approval",
        beforeEnter: (to, from, next) => {
            store.getters.isAuthenticated ? next(`/portal${to.fullPath}`) : next()
        },
        children: [
            {
                path: ":uuid",
                name: "sanadApproval",
                component: () => import("@/views/main/sanad/approval/Approval.vue"),
                meta: { title: i18n.global.t("sanadApproval") }
            },
            {
                path: "preview/:uuid",
                name: "sanadApprovalPreview",
                component: () => import("@/views/main/sanad/approval/ApprovalPreview.vue"),
                meta: { title: i18n.global.t("sanadApprovalPreview") }
            },
            {
                path: "confirmation/:uuid",
                name: "sanadApprovalConfirmation",
                component: () =>
                    import("@/views/main/sanad/approval/ApprovalConfirmation.vue"),
                meta: { title: i18n.global.t("sanadApprovalConfirmation") }
            },
            {
                path: "confirmation/success/:uuid",
                name: "sanadApprovalConfirmationSuccess",
                component: () =>
                    import("@/views/main/sanad/approval/ApprovalConfirmationSuccess.vue"),
                meta: { title: i18n.global.t("sanadApprovalConfirmationSuccess") }
            }
        ]
    },
    {
        path: "/portal/sanad/approval",
        children: [
            {
                path: ":uuid",
                name: "portalSanadApproval",
                component: () => import("@/views/main/sanad/approval/Approval.vue"),
                meta: { requiresAuth: true, title: i18n.global.t("sanadApproval") }
            },
            {
                path: "preview/:uuid",
                name: "portalSanadApprovalPreview",
                component: () => import("@/views/main/sanad/approval/ApprovalPreview.vue"),
                meta: { requiresAuth: true, title: i18n.global.t("sanadApprovalPreview") }
            },
            {
                path: "confirmation/:uuid",
                name: "portalSanadApprovalConfirmation",
                component: () =>
                    import("@/views/main/sanad/approval/ApprovalConfirmation.vue"),
                meta: {
                    requiresAuth: true,
                    title: i18n.global.t("sanadApprovalConfirmation")
                }
            },
            {
                path: "confirmation/success/:uuid",
                name: "portalSanadApprovalConfirmationSuccess",
                component: () =>
                    import("@/views/main/sanad/approval/ApprovalConfirmationSuccess.vue"),
                meta: {
                    requiresAuth: true,
                    title: i18n.global.t("sanadApprovalConfirmationSuccess")
                }
            }
        ]
    },
    // Sanad export views
    {
        path: "/sanad/export/:uuid/:sanad_uuid?",
        name: "sanadExport",
        component: () => import("@/views/main/sanad/export/ExportPDF.vue"),
        meta: {
            title: i18n.global.t("sanadExport")
        }
    },
    // Portal - sanad management views
    {
        path: "/portal/sanad/management/",
        name: "portalSanadManagement",
        meta: {
            requiresAuth: true,
            title: i18n.global.t("sanadsManagement"),
            canAccess: ({ type, company, permissions }) =>
                type === userTypes.INDIVIDUAL ||
                (type === userTypes.COMPANY &&
                    (company?.is_owner ||
                        permissions?.some(permission =>
                            [
                                "sanad.view_company_sanad",
                                "sanad.create_sanad",
                                "sanad.cancel_company_sanad",
                                "sanad.close_own_sanad",
                                "sanad.close_company_sanad",
                                "sanad.edit_company_sanad",
                                "sanad.delete_company_sanad"
                            ].includes(permission)
                        )))
        },
        redirect: () => {
            const { type, company, permissions } = store.getters.getUser
            const category =
                type === userTypes.INDIVIDUAL
                    ? "portalSanadManagementPendingSanads"
                    : type === userTypes.COMPANY &&
                        !company?.is_owner &&
                        permissions?.includes("sanad.create_sanad")
                        ? "portalSanadManagementCreditOwnSanads"
                        : "portalSanadManagementCreditSanads"
            return { name: category }
        },
        component: () => import("@/views/portal/sanad/management/SanadManagement.vue"),
        children: [
            {
                path: "/portal/sanad/management/pending-sanads",
                name: "portalSanadManagementPendingSanads",
                component: () =>
                    import(
                        "@/components/portal/sanad/management/SanadAwaitingApproval.vue"
                    ),
                meta: {
                    title: i18n.global.t("approvePenddingSanad")
                }
            },
            {
                path: "/portal/sanad/management/debit-sanads",
                name: "portalSanadManagementDebitSanads",
                component: () =>
                    import("@/components/portal/sanad/management/SanadDebit.vue"),
                meta: {
                    title: i18n.global.t("debitSanads")
                }
            },
            {
                path: "/portal/sanad/management/credit-sanads",
                name: "portalSanadManagementCreditSanads",
                component: () =>
                    import("@/components/portal/sanad/management/SanadCredit.vue"),
                meta: {
                    title: i18n.global.t("creditSanads")
                }
            },
            {
                path: "/portal/sanad/management/credit-own-sanads",
                name: "portalSanadManagementCreditOwnSanads",
                component: () =>
                    import("@/components/portal/sanad/management/SanadCreditOwn.vue"),
                meta: {
                    title: i18n.global.t("creditOwnSanads")
                }
            }
        ]
    },
    // Portal - sanad settlement views
    {
        path: "/portal/sanad/settlement/",
        name: "portalSanadSettlement",
        meta: {
            requiresAuth: true,
            canAccess: ({ settings, type }) =>
                !!settings?.enable_sanad_settlement_feature &&
                type === userTypes.INDIVIDUAL
        },
        component: () => import("@/views/portal/sanad/settlement/SanadSettlement.vue"),
        children: [
            {
                path: "payment/:uuid",
                name: "portalSanadSettlementPayment",
                component: () =>
                    import("@/views/portal/sanad/settlement/SanadSettlementPayment.vue"),
                meta: {
                    title: i18n.global.t("paySanadValue")
                }
            },
            {
                path: "payment-history/:uuid",
                name: "portalSanadSettlementPaymentHistory",
                component: () =>
                    import(
                        "@/views/portal/sanad/settlement/SanadSettlementPaymentHistory.vue"
                    ),
                meta: {
                    title: i18n.global.t("sanadPaymentHistory")
                }
            },
            {
                path: "invoice/:invoiceId/:uuid",
                name: "portalSanadSettlementInvoice",
                meta: { requiresAuth: true, title: i18n.global.t("paySadadInvoice") },
                component: () =>
                    import("@/views/portal/sanad/settlement/SanadSettlementInvoice.vue")
            }
        ]
    },
    // Portal - payment views
    {
        path: "/portal/payment/payment-result/",
        name: "portalPaymentResult",
        meta: {
            requiresAuth: true,
            title: i18n.global.t("portalPaymentResult")
        },
        component: () => import("@/views/portal/payment/PaymentResult.vue")
    },
    // Portal - company views
    {
        path: "/portal/company/integration/",
        name: "portalCompanyIntegration",
        meta: {
            requiresAuth: true,
            title: i18n.global.t("companyIntegration"),
            canAccess: ({ company, permissions }) =>
                company?.has_api_application &&
                (company?.is_owner ||
                    permissions?.includes("membership.access_integration"))
        },
        component: () => import("@/components/portal/company/Integration.vue"),
        redirect: { name: "portalCompanyIntegrationAppInformation" },
        children: [
            {
                path: "/portal/company/integration/app-information/",
                name: "portalCompanyIntegrationAppInformation",
                component: () =>
                    import("@/views/portal/company/integration/AppInformation.vue"),
                meta: {
                    title: i18n.global.t("applicationInformation")
                }
            },
            {
                path: "/portal/company/integration/onboarding-details/",
                name: "portalCompanyIntegrationOnboardingDetails",
                component: () =>
                    import("@/views/portal/company/integration/OnboardingDetails.vue"),
                meta: {
                    title: i18n.global.t("onboardingDetails")
                }
            },
            {
                path: "/portal/company/integration/apis-documentation/",
                name: "portalCompanyIntegrationAPIsDocumentation",
                component: () =>
                    import("@/views/portal/company/integration/APIsDocumentation.vue"),
                meta: {
                    title: i18n.global.t("apiDoc")
                }
            }
        ]
    },
    {
        path: "/portal/company/integrator/",
        name: "portalCompanyIntegrator",
        meta: {
            requiresAuth: true,
            canAccess: ({ type }) => type === userTypes.INTEGRATOR,
            title: i18n.global.t("companyIntegration")
        },
        component: () => import("@/components/portal/company/integrator/Integrator.vue"),
        redirect: { name: "portalCompanyIntegratorAppInformation" },
        children: [
            {
                path: "/portal/company/integrator/app-information/",
                name: "portalCompanyIntegratorAppInformation",
                component: () =>
                    import("@/views/portal/company/integrator/AppInformation.vue"),
                meta: {
                    title: i18n.global.t("applicationInformation")
                }
            }
        ]
    },
    {
        path: "/portal/company/settings/",
        name: "portalCompanySettings",
        meta: {
            requiresAuth: true,
            title: i18n.global.t("platformSettings"),
            canAccess: ({ company, permissions }) =>
                company?.is_owner ||
                permissions?.includes("membership.company_admin_manager")
        },
        component: () => import("@/views/portal/company/CompanySettings.vue")
    },
    {
        path: "/portal/company/employees",
        name: "portalCompanyEmployeesManagement",
        beforeEnter: (to, from, next) => {
            const { company } = store.getters.getUser
            company?.business_type === companyBusinessTypes.LAWYER
                ? next({ name: "portalLawyersManagement" })
                : next()
        },
        meta: {
            requiresAuth: true,
            title: i18n.global.t("usersManagement"),
            canAccess: ({ type, company, permissions }) =>
                type === userTypes.COMPANY &&
                (company?.is_owner ||
                    permissions?.some(permission =>
                        [
                            "membership.add_company_user",
                            "membership.change_user_permissions",
                            "membership.manage_users_activation",
                            "membership.company_admin_manager"
                        ].includes(permission)
                    ))
        },
        redirect: { name: "portalCompanyEmployeesManagementActive" },
        component: () =>
            import("@/views/portal/company/employee_management/EmployeeList.vue"),
        children: [
            {
                path: "/portal/company/employees/active-employees",
                name: "portalCompanyEmployeesManagementActive",
                component: () =>
                    import(
                        "@/components/portal/company/company_management/ActiveEmployees.vue"
                    ),
                meta: {
                    title: i18n.global.t("activeEmployees")
                }
            },
            {
                path: "/portal/company/employees/inactive-employees",
                name: "portalCompanyEmployeesManagementInactive",
                component: () =>
                    import(
                        "@/components/portal/company/company_management/InactiveEmployees.vue"
                    ),
                meta: {
                    title: i18n.global.t("inactiveEmployees")
                }
            },
            {
                path: "/portal/company/employees/mailing-list-employees",
                name: "portalCompanyEmployeesManagementMailingList",
                component: () =>
                    import(
                        "@/components/portal/company/company_management/MailingListEmployees.vue"
                    ),
                meta: {
                    title: i18n.global.t("mailingListEmployees")
                }
            }
        ]
    },
    {
        path: "/portal/company/employee",
        name: "portalCompanyEmployeeManagement",
        meta: {
            requiresAuth: true,
            title: i18n.global.t("usersManagement"),
            canAccess: ({ type, company, permissions }) =>
                type === userTypes.COMPANY &&
                company?.business_type === companyBusinessTypes.GENERAL &&
                (company?.is_owner ||
                    permissions?.some(permission =>
                        [
                            "membership.add_company_user",
                            "membership.change_user_permissions",
                            "membership.manage_users_activation",
                            "membership.company_admin_manager"
                        ].includes(permission)
                    ))
        },
        children: [
            {
                path: "add-new",
                name: "portalCompanyEmployeesManagementAddNew",
                component: () =>
                    import("@/views/portal/company/employee_management/AddEmployee.vue"),
                meta: {
                    title: i18n.global.t("addNewEmployee")
                }
            },
            {
                path: "set-password/:employeeId",
                name: "portalCompanyEmployeesManagementSetPassword",
                component: () =>
                    import(
                        "@/views/portal/company/employee_management/ChangeEmployeePassword.vue"
                    ),
                meta: {
                    title: i18n.global.t("changePassword")
                }
            },
            {
                path: "update-email/:employeeId",
                name: "portalCompanyEmployeesManagementUpdateEmail",
                component: () =>
                    import(
                        "@/views/portal/company/employee_management/ChangeEmployeeEmail.vue"
                    ),
                meta: {
                    title: i18n.global.t("changeEmail")
                }
            },
            {
                path: "set-permissions/:status/:employeeId",
                name: "portalCompanyEmployeesManagementSetPermissions",
                component: () =>
                    import(
                        "@/views/portal/company/employee_management/SetEmployeePermissions.vue"
                    ),
                meta: {
                    title: i18n.global.t("changePermissions")
                }
            }
        ]
    },
    {
        path: "/portal/callback-history",
        name: "portalCallbackHistoryManagement",
        meta: {
            requiresAuth: true,
            title: i18n.global.t("callbackHistoryManagement"),
            canAccess: ({ company, permissions, type }) =>
                (company?.has_callback_url &&
                    (company?.is_owner ||
                        permissions?.includes("membership.callback_history_access"))) ||
                type === userTypes.INTEGRATOR
        },
        redirect: { name: "portalCallbackHistoryManagementInprogress" },
        component: () =>
            import("@/views/portal/company/callback_history/CallbackHistoryList.vue"),
        children: [
            {
                path: "/portal/callback-history/in-progress",
                name: "portalCallbackHistoryManagementInprogress",
                component: () =>
                    import(
                        "@/components/portal/company/callback_history/WorkInProgress.vue"
                    ),
                meta: {
                    title: i18n.global.t("workInProgress")
                }
            },
            {
                path: "/portal/callback-history/communicated",
                name: "portalCallbackHistoryManagementCommunicated",
                component: () =>
                    import(
                        "@/components/portal/company/callback_history/CommunicationDone.vue"
                    ),
                meta: {
                    title: i18n.global.t("communicated")
                }
            },
            {
                path: "/portal/callback-history/communicate-failed",
                name: "portalCallbackHistoryManagementCommunicationFailed",
                component: () =>
                    import(
                        "@/components/portal/company/callback_history/CommunicationFailed.vue"
                    ),
                meta: {
                    title: i18n.global.t("communicationFailed")
                }
            },
            {
                path: "/portal/callback-history/transmit-failed",
                name: "portalCallbackHistoryManagementTransmissionFailed",
                component: () =>
                    import(
                        "@/components/portal/company/callback_history/TransmissionFailed.vue"
                    ),
                meta: {
                    title: i18n.global.t("transmissionFailed")
                }
            }
        ]
    },
    // Portal - financial details views
    {
        path: "/portal/financial-details/",
        meta: {
            requiresAuth: true,
            title: i18n.global.t("financialDetails"),
            canAccess: ({ isSuperAdmin, type, company, permissions }) =>
                type === userTypes.INDIVIDUAL ||
                (userTypes.COMPANY &&
                    (company?.is_owner ||
                        (company?.is_verified &&
                            permissions?.includes("billing.access_invoice")) ||
                        (!company?.is_verified &&
                            permissions?.includes(
                                "membership.access_financial_details"
                            )))) ||
                (type === userTypes.ADMIN &&
                    (isSuperAdmin || permissions?.includes("auth.user_management")))
        },
        children: [
            {
                path: "",
                name: "portalFinancialDetails",
                component: () =>
                    import("@/views/portal/financial_details/FinancialDetails.vue")
            },
            {
                path: "sanads/payment-history/",
                name: "portalFinancialDetailsSanadPaymentHistory",
                meta: {
                    requiresAuth: true,
                    title: i18n.global.t("financialDetailsSanadPaymentHistory"),
                    canAccess: ({ type, company, permissions }) =>
                        type === userTypes.INDIVIDUAL ||
                        (userTypes.COMPANY &&
                            (company?.is_owner ||
                                permissions?.some(permission =>
                                    [
                                        "billing.access_invoice",
                                        "membership.access_financial_details"
                                    ].includes(permission)
                                )))
                },
                component: () =>
                    import("@/views/portal/financial_details/SanadPaymentHistory.vue")
            },
            {
                path: "packages/",
                name: "portalFinancialDetailsPackages",
                meta: {
                    requiresAuth: true,
                    title: i18n.global.t("financialDetailsPackages"),
                    canAccess: ({ isSuperAdmin, type, company, permissions }) =>
                        (type === userTypes.COMPANY &&
                            (company?.is_owner ||
                                permissions?.some(permission =>
                                    [
                                        "billing.access_invoice",
                                        "membership.access_financial_details"
                                    ].includes(permission)
                                ))) ||
                        (type === userTypes.ADMIN &&
                            (isSuperAdmin ||
                                permissions?.includes("membership.company_admin_manager")))
                },
                redirect: { name: "portalFinancialDetailsPackagesHistory" },
                children: [
                    {
                        path: "list/",
                        name: "portalFinancialDetailsPackagesList",
                        component: () =>
                            import(
                                "@/views/portal/financial_details/prepaid_packages/PrepaidPackages.vue"
                            ),
                        meta: {
                            title: i18n.global.t("financialDetailsPackagesList")
                        }
                    },
                    {
                        path: "packages-history/",
                        name: "portalFinancialDetailsPackagesHistory",
                        component: () =>
                            import(
                                "@/views/portal/financial_details/prepaid_packages/PackagesHistory.vue"
                            ),
                        meta: {
                            title: i18n.global.t("financialDetailsPackagesHistory")
                        }
                    },
                    {
                        path: "sadad-invoice-success/:invoiceId/",
                        name: "portalFinancialDetailsPackagesSadadInvoiceSuccess",
                        component: () =>
                            import(
                                "@/views/portal/financial_details/prepaid_packages/PackagesSadadInvoiceSuccess.vue"
                            ),
                        meta: {
                            title: i18n.global.t("paySadadInvoice")
                        }
                    },
                    {
                        path: "packages-balance/",
                        name: "portalFinancialDetailsPackagesBalance",
                        component: () =>
                            import(
                                "@/views/portal/financial_details/prepaid_packages/PackagesBalance.vue"
                            ),
                        meta: {
                            title: i18n.global.t("financialDetailsPackagesBalance")
                        }
                    }
                ]
            },
            {
                path: "monthly-invoices/",
                name: "portalFinancialDetailsMonthlyInvoices",
                meta: {
                    requiresAuth: true,
                    title: i18n.global.t("financialDetailsMonthlyInvoices"),
                    canAccess: ({ isSuperAdmin, type, company, permissions }) =>
                        (type === userTypes.COMPANY &&
                            (company?.is_owner ||
                                (company?.is_verified &&
                                    permissions?.includes("billing.access_invoice")) ||
                                (!company?.is_verified &&
                                    permissions?.includes(
                                        "membership.access_financial_details"
                                    )))) ||
                        (type === userTypes.ADMIN &&
                            (isSuperAdmin ||
                                permissions?.includes("auth.user_management")))
                },
                redirect: { name: "portalFinancialDetailsMonthlyInvoicesList" },
                children: [
                    {
                        path: "list/",
                        name: "portalFinancialDetailsMonthlyInvoicesList",
                        component: () =>
                            import(
                                "@/views/portal/financial_details/monthly_billing_system/MonthlyInvoices.vue"
                            ),
                        meta: {
                            title: i18n.global.t("financialDetailsMonthlyInvoicesList")
                        }
                    },
                    {
                        path: "invoiced-sanads/:invoiceId",
                        name: "portalFinancialDetailsMonthlyInvoicesInvoicedSanads",
                        component: () =>
                            import(
                                "@/views/portal/financial_details/monthly_billing_system/InvoicedSanads.vue"
                            ),
                        meta: {
                            title: i18n.global.t(
                                "financialDetailsMonthlyInvoicesInvoicedSanads"
                            )
                        }
                    }
                ]
            },
            {
                path: "annual-subscription/",
                name: "portalFinancialDetailsAnnualSubscription",
                meta: {
                    requiresAuth: true,
                    title: i18n.global.t("financialDetailsAnnualSubscription"),
                    canAccess: ({ isSuperAdmin, type, company, permissions }) =>
                        (type === userTypes.COMPANY &&
                            (company?.is_owner ||
                                (company?.is_verified &&
                                    permissions?.includes("billing.access_invoice")) ||
                                (!company?.is_verified &&
                                    permissions?.includes(
                                        "membership.access_financial_details"
                                    )))) ||
                        (type === userTypes.ADMIN &&
                            (isSuperAdmin ||
                                permissions?.includes("auth.user_management")))
                },
                component: () =>
                    import(
                        "@/views/portal/financial_details/annual_subscription/AnnualSubscription.vue"
                    )
            },
            {
                path: "api-subscription/",
                name: "portalFinancialDetailsAPISubscription",
                meta: {
                    requiresAuth: true,
                    title: i18n.global.t("financialDetailsAPISubscription"),
                    canAccess: ({ isSuperAdmin, type, company, permissions }) =>
                        (type === userTypes.COMPANY &&
                            (company?.is_owner ||
                                (company?.is_verified &&
                                    permissions?.includes("billing.access_invoice")) ||
                                (!company?.is_verified &&
                                    permissions?.includes(
                                        "membership.access_financial_details"
                                    )))) ||
                        (type === userTypes.ADMIN &&
                            (isSuperAdmin ||
                                permissions?.includes("auth.user_management")))
                },
                component: () =>
                    import(
                        "@/views/portal/financial_details/api_integration/APIIntegration.vue"
                    )
            }
        ]
    },

    // Portal - reports views
    {
        path: "/portal/reports/",
        meta: {
            requiresAuth: true,
            title: i18n.global.t("reports"),
            canAccess: ({ type, company, permissions }) =>
                (type === userTypes.COMPANY &&
                    (company?.is_owner ||
                        permissions?.some(permission =>
                            [
                                "reports.access_report",
                                "reports.reports_manager",
                                "reports.detailed_report"
                            ].includes(permission)
                        ))) ||
                (type === userTypes.ADMIN && permissions?.includes("auth.moj_management"))
        },
        children: [
            {
                path: "",
                name: "portalReports",
                component: () => import("@/views/portal/reports/Reports.vue")
            },
            {
                path: "sanad-detailed/",
                name: "portalReportsDetailedSanad",
                component: () => import("@/views/portal/reports/Detailed.vue"),
                meta: {
                    title: i18n.global.t("reportsDetailedSanad")
                }
            },
            {
                path: "financial-consumption/",
                name: "portalReportsFinancialConsumption",
                component: () => import("@/views/portal/reports/FinancialConsumption.vue"),
                meta: {
                    title: i18n.global.t("reportsFinancialConsumption")
                }
            }
        ]
    },
    // Portal - v1 views
    {
        path: "/portal/contracts/",
        name: "portalContract",
        meta: {
            requiresAuth: true,
            canAccess: ({ company }) =>
                company?.business_type === companyBusinessTypes.LAWYER
        },
        redirect: () => {
            return window.location.replace(
                `${backendBaseURL}/contract/contract-management`
            )
        }
    },
    {
        path: "/portal/contracts/lawyers/",
        name: "portalLawyersManagement",
        meta: {
            requiresAuth: true,
            canAccess: ({ company }) =>
                company?.business_type === companyBusinessTypes.LAWYER
        },
        redirect: () => {
            return window.location.replace(`${backendBaseURL}/membership/company/users/`)
        }
    },
    {
        path: "/company-onboarding/legal-and-low-firms/",
        name: "legalAndLowFirmsCompanyOnboarding",
        meta: {
            requiresAuth: false,
            canAccess: ({ settings, company }) =>
                !!settings?.enable_companies_registrations &&
                company?.business_type === companyBusinessTypes.LAWYER
        },
        redirect: () => {
            return window.location.replace(
                `${backendBaseURL}/company-onboarding/register-lawyer-company/`
            )
        }
    },
    // Error views
    {
        path: "/unauthorized",
        name: "portalUnauthorized",
        meta: { requiresAuth: true, title: i18n.global.t("unauthorized") },
        component: () => import("@/views/auth/UnauthorizedView.vue")
    },
    // Redirect view
    {
        path: "/redirect/",
        name: "redirect"
    }
]

const router = createRouter({
    history: createWebHistory(frontendBaseRoute),
    routes
})

router.beforeEach(async (to, from, next) => {
    try {
        await store.dispatch("fetchSettings")

        const { isAuthenticated, getUser: user, getSettings: settings } = store.getters
        const { requiresAuth, canAccess = () => true } = to.meta
        const {
            type,
            company,
            permissions,
            is_superuser: isSuperAdmin,
            is_password_expired: isPasswordExpired
        } = user || {}
        const isPortalRoute = to.name && to.name.startsWith("portal")
        const isHomeRoute = from.name === "home"
        const isUnauthorizedRoute = to.name === "portalUnauthorized"
        const isForceChangePasswordRoute = to.name === "forceChangePassword"
        const isSessionExpired = !!localStorage.getItem("sessionExpired")

        const navigateTo = routeName =>
            next(to.name !== routeName ? { name: routeName } : false)

        // Redirect to home page if session is expired
        if (!isAuthenticated && isSessionExpired && !isHomeRoute) {
            localStorage.removeItem("sessionExpired")
            localStorage.setItem("redirectAfterLogin", from.path)
            return navigateTo("home")
        }

        // Redirect to home page if user is not authenticate or doesn't have access to the route
        if (
            (!isAuthenticated && requiresAuth) ||
            (requiresAuth === false && isAuthenticated) ||
            !canAccess({ isSuperAdmin, type, company, permissions, settings })
        ) {
            return navigateTo("home")
        }

        // Redirect to force change password page if password is expired
        if (
            isAuthenticated &&
            isPasswordExpired &&
            isPortalRoute &&
            !isForceChangePasswordRoute
        ) {
            return navigateTo("forceChangePassword")
        }

        // Redirect to unauthorized page if company user doesn't have any permissions
        if (
            !isSuperAdmin &&
            !!company?.length &&
            !company?.is_owner &&
            !permissions?.length &&
            isPortalRoute &&
            !isUnauthorizedRoute
        ) {
            return navigateTo("portalUnauthorized")
        }

        // Redirect to preserved route after login when session was expired
        const redirectAfterLogin =
            isAuthenticated && localStorage.getItem("redirectAfterLogin")
        if (redirectAfterLogin) {
            localStorage.removeItem("redirectAfterLogin")
            return next(redirectAfterLogin)
        }

        next()
    } catch (error) {
        console.error("Redirection in navigation error:", error)
        next(false)
    }

    // Set page title
    document.title = `${i18n.global.t("nafithPlatform")} | ${to?.meta?.title ? to?.meta?.title : ""}`
})

export default router
